import React from "react";
import * as yup from "yup";
import { Formik, FormikProps, FormikActions } from "formik";
import {
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Button,
} from "reactstrap";

import { ICommunityActivity } from "../../interfaces";
import { AxiosPromise } from "axios";

const formSchema = yup.object().shape({
  role: yup
    .string()
    .label("Role")
    .required(),
  event: yup
    .string()
    .label("Event")
    .required(),
  description: yup
    .string()
    .max(142)
    .label("Description")
    .required(),
});

interface ICommunityActivityFormProps {
  communityActivity: ICommunityActivity;
  formIsDisabled: boolean;
  onSubmit(
    communityActivity: ICommunityActivity
  ): AxiosPromise<ICommunityActivity>;
  onDelete(communityActivity: ICommunityActivity): void;
}

const CommunityActivityForm: React.FC<ICommunityActivityFormProps> = props => {
  const { communityActivity, formIsDisabled } = props;

  function handleSubmit(
    values: ICommunityActivity,
    actions: FormikActions<ICommunityActivity>
  ) {
    props
      .onSubmit({
        ...values,
      })
      .then(() => {
        actions.setSubmitting(false);
        actions.resetForm(values);
      });
  }

  return (
    <Formik
      initialValues={{
        ...communityActivity,
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<ICommunityActivity>) => (
        <Form onSubmit={formikBag.handleSubmit}>
          <FormGroup>
            <Label>
              Role <span className="text-danger">*</span>
            </Label>
            <Input
              name="role"
              value={formikBag.values.role || ""}
              onChange={formikBag.handleChange}
              invalid={!!(formikBag.errors.role && formikBag.touched.role)}
              disabled={formIsDisabled}
            />
            <FormFeedback>{formikBag.errors.role}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>
              Event/Activity <span className="text-danger">*</span>
            </Label>
            <Input
              name="event"
              value={formikBag.values.event || ""}
              onChange={formikBag.handleChange}
              invalid={!!(formikBag.errors.event && formikBag.touched.event)}
              disabled={formIsDisabled}
            />
            <FormFeedback>{formikBag.errors.event}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>
              Description <span className="text-danger">*</span>
            </Label>
            <Input
              name="description"
              type="textarea"
              rows="4"
              value={formikBag.values.description || ""}
              onChange={formikBag.handleChange}
              invalid={
                !!(
                  formikBag.errors.description && formikBag.touched.description
                )
              }
              disabled={formIsDisabled}
            />
            <FormFeedback>{formikBag.errors.description}</FormFeedback>
          </FormGroup>
          <div className="d-flex justify-content-between">
            <Button
              className="mr-2"
              onClick={() => props.onDelete(communityActivity)}
              disabled={formIsDisabled}
            >
              Delete
            </Button>
            <Button
              color="primary"
              type="submit"
              className={formikBag.dirty ? "d-block" : "d-none"}
              disabled={formIsDisabled}
            >
              Save
            </Button>
          </div>
          <hr />
        </Form>
      )}
    />
  );
};

export default CommunityActivityForm;
