import React from "react";
import { RouteComponentProps } from "@reach/router";

import iconLogoC from "../images/icon-logo-c.svg";

const Loading: React.FC<RouteComponentProps> = props => {
  return (
    <div className="text-center text-light h-100 d-flex flex-column justify-content-center">
      <img src={iconLogoC} alt="Rec Hub logo icon" className="mb-3" />
      <h1 className="text-center mb-3">Loading...</h1>
    </div>
  );
};

export default Loading;
