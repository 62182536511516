import React from "react";

import { Row, Col, Card, CardBody } from "reactstrap";

const SignUpComplete: React.FC = () => {
  return (
    <Row>
      <Col sm={{ size: 8, offset: 2 }}>
        <Card className="rh-bg-teal border-0">
          <CardBody className="text-center">
            <h2 className="mb-4">Ready to get started? Check your email.</h2>
            <p className="h4">
              To get started, please go to your inbox and locate your order
              confirmation email to access the link that will take you to your
              personalized version of Rec Hub.
            </p>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default SignUpComplete;
