import React from "react";

import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";

import {
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { IAccountSettings } from "../interfaces";
import { AxiosPromise } from "axios";

export interface ISecondaryContactFormValues {
  secondary_full_name: string;
  secondary_email: string;
}

const formSchema = yup.object().shape({
  secondary_full_name: yup
    .string()
    .label("First and Last Name")
    .required(),
  secondary_email: yup
    .string()
    .email()
    .label("Email")
    .required(),
});

interface ISecondaryContactFormProps {
  className?: string;
  isOpen: boolean;
  accountData: IAccountSettings;
  onClose(): void;
  onSave(accountData: IAccountSettings): AxiosPromise<IAccountSettings>;
}

const SecondaryContactFormModal: React.FC<
  ISecondaryContactFormProps
> = props => {
  function handleSubmit(
    values: ISecondaryContactFormValues,
    actions: FormikActions<ISecondaryContactFormValues>
  ) {
    const accountData = {
      ...props.accountData,
      ...values,
    };
    props.onSave(accountData).then(() => {
      actions.setSubmitting(false);
      props.onClose();
    });
  }

  return (
    <Formik
      initialValues={{
        secondary_full_name: props.accountData.secondary_full_name || "",
        secondary_email: props.accountData.secondary_email || "",
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<ISecondaryContactFormValues>) => (
        <Modal
          isOpen={props.isOpen}
          toggle={props.onClose}
          className={props.className}
        >
          <Form onSubmit={formikBag.handleSubmit}>
            <ModalHeader toggle={props.onClose}>Secondary Contact</ModalHeader>
            <ModalBody className="rh-bg-teal">
              <FormGroup>
                <Label>First and Last Name (parent/guardian)</Label>
                <Input
                  name="secondary_full_name"
                  value={formikBag.values.secondary_full_name}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.secondary_full_name &&
                      formikBag.touched.secondary_full_name
                    )
                  }
                />
                <FormFeedback>
                  {formikBag.errors.secondary_full_name}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Email Address</Label>
                <Input
                  name="secondary_email"
                  value={formikBag.values.secondary_email}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.secondary_email &&
                      formikBag.touched.secondary_email
                    )
                  }
                />
                <FormFeedback>{formikBag.errors.secondary_email}</FormFeedback>
              </FormGroup>
            </ModalBody>
            <ModalFooter className="rh-bg-teal text-right">
              <Button disabled={formikBag.isSubmitting} onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={formikBag.isSubmitting}
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    />
  );
};

export default SecondaryContactFormModal;
