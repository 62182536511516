import React from "react";
import dayjs from "dayjs";

import { Container } from "reactstrap";

interface IFooterProps {
  className?: string;
}

const Footer: React.FC<IFooterProps> = props => {
  return (
    <footer className={`d-print-none rh-bg-gray-200 ${props.className}`}>
      <Container className="text-center py-4">
        <p className="mb-0 text-muted">
          &copy; {dayjs().format("YYYY")} All Rights Reserved.
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
