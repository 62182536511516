import React, { useState, useEffect } from "react";
import { navigate, Router, Redirect, Location } from "@reach/router";
import axios from "axios";
import * as contentful from "contentful";
import ReactGA from "react-ga";

import agent from "./agent";
import * as routes from "./routes";
import {
  parseContentfulPages,
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_ACCESS_TOKEN,
} from "./utils/contentfulHelpers";
import {
  IUser,
  IAccountSettings,
  IContentfulPage,
  IUniversity,
} from "./interfaces";
import UserSettingsContext from "./context/user-settings-context";
import { EMPTY_USER } from "./constants";

import Loading from "./pages/Loading";
import CreateAccount from "./pages/CreateAccount";
import Payment from "./pages/Payment";
import Dashboard from "./pages/Dashboard";
import RecInvites from "./pages/RecInvites";
import Files from "./pages/Files";
import Account from "./pages/Account";
import Settings from "./pages/Settings";
import Resume from "./pages/Resume";
import SignIn from "./pages/SignIn";
import SignOut from "./pages/SignOut";
import RecRequests from "./pages/RecRequests";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordReset from "./pages/PasswordReset";
import EmailConfirmation from "./pages/EmailConfirmation";
import RWSignUp from "./pages/RWSignUp";
import RecDetail from "./pages/RecDetail";
import Privacy from "./pages/Privacy";
import PaidRoute from "./components/PaidRoute";

// @ts-ignore
// const history = createHistory(window);
// ReactGA.initialize("UA-136345312-1");
// ReactGA.pageview(window.location.pathname);
// history.listen(() => {
//   ReactGA.pageview(window.location.pathname);
// });

const contentfulClient = contentful.createClient({
  space: CONTENTFUL_SPACE_ID,
  accessToken: CONTENTFUL_ACCESS_TOKEN,
});

const App: React.FC = () => {
  const [pages, setPages] = useState<IContentfulPage[] | null>(null);
  useEffect(() => {
    ReactGA.initialize("UA-136345312-1");
    ReactGA.pageview(window.location.pathname);

    contentfulClient
      .getEntries({
        content_type: "page",
      })
      .then(entries => {
        const parsedPages = parseContentfulPages(entries);
        setPages(parsedPages);
      });
  }, []);

  const [user, setUser] = useState<IUser>(EMPTY_USER);
  function updateUser(updatedUser: IUser) {
    setUser({
      ...user,
      ...updatedUser,
    });
  }

  const [settings, setSettings] = useState<IAccountSettings>({
    amount_paid: "",
    display_name: "",
    email: "",
    email_frequency: "",
    first_name: "",
    full_name: "",
    last_name: "",
    mobile_number: "",
    notify_primary: false,
    notify_secondary: false,
    secondary_display_name: "",
    secondary_email: "",
    secondary_full_name: "",
    secondary_mobile_number: "",
    send_notification_emails: false,
    user_type: "",
  });
  function updateSettings(updatedSettings: IAccountSettings) {
    setSettings({
      ...settings,
      ...updatedSettings,
    });
  }

  useEffect(() => {
    axios.defaults.withCredentials = true;
    fetchUserSettings();
  }, []);

  const [loading, setLoading] = useState(true);
  async function fetchUserSettings() {
    setLoading(true);
    try {
      const userRes = await agent.Auth.getUser();
      setUser(userRes.data);
      ReactGA.set({
        userId: userRes.data.id,
        dimension1: userRes.data.user_type,
      });

      // @ts-ignore
      const FS = window ? window.FS : undefined;
      if (userRes && userRes.data.id && userRes.data.email) {
        if (FS && FS.identify) {
          // @ts-ignore
          FS.identify(userRes.data.id, {
            displayName: `${userRes.data.first_name} ${userRes.data.last_name}`,
            email: userRes.data.email,
            userType_str: userRes.data.user_type,
          });
        }
      }

      if (
        userRes.data &&
        userRes.data.user_type === "pnm" &&
        !userRes.data.paid
      ) {
        // note, this check is only client-side and can easily be circumvented
        // we should still rely on the server to prevent an unpaid user
        // from doing paid-only activities in the app
        navigate(routes.toPayment);
      }
      const settingsRes = await agent.User.getSettings();
      setSettings(settingsRes.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  async function handleSuccessfulSignIn() {
    await fetchUserSettings();
    navigate(routes.toDashboard);
  }

  function handleSignOut() {
    agent.Auth.logout().then(() => {
      setUser(EMPTY_USER);
      setSettings({
        amount_paid: "",
        display_name: "",
        email: "",
        email_frequency: "",
        first_name: "",
        full_name: "",
        last_name: "",
        mobile_number: "",
        notify_primary: false,
        notify_secondary: false,
        secondary_display_name: "",
        secondary_email: "",
        secondary_full_name: "",
        secondary_mobile_number: "",
        send_notification_emails: false,
        user_type: "",
      });
      navigate(routes.toSignIn);
    });
  }

  function handlePasswordReset() {
    navigate(routes.toSignIn, {
      state: { isFromSuccessfulPasswordReset: true },
    });
  }

  async function handleEmailConfirmation() {
    await fetchUserSettings();
    navigate(routes.toDashboard);
  }

  async function authenticateUserForPayment(email: string, password: string) {
    await agent.Auth.login(email, password);
    const userRes = await agent.Auth.getUser();
    if (userRes.data && userRes.data.id) {
      setUser(userRes.data);
      navigate(routes.toPayment);
    }
  }

  const [universityList, setUniversityList] = useState<IUniversity[]>([]);
  useEffect(() => {
    agent.Universities.get().then(res => {
      setUniversityList(res.data.universities);
    });
  }, []);

  const [userUniversityName, setUserUniversityName] = useState("");
  useEffect(() => {
    if (user && universityList) {
      const foundUniversity = universityList.find(
        u => u.id === user.university_id
      );
      if (foundUniversity) {
        setUserUniversityName(foundUniversity.friendly_name);
      } else {
        setUserUniversityName("");
      }
    }
  }, [user, universityList]);

  if (loading || !pages) {
    return <Loading />;
  }

  if (!user.id) {
    return (
      <Location>
        {({ location }) => {
          if (location && location.pathname) {
            ReactGA.pageview(location.pathname);
            if (location.search && location.search.includes("rwRedirect")) {
              navigate(routes.toRwSignUpPath);
            }
          }
          return (
            <Router>
              {/* RecHub will use a website builder (Wix) for the home page and marketing site */}
              {/* <Home default path={routes.rootPath} /> */}
              <Redirect noThrow from={routes.rootPath} to={routes.signInPath} />
              <CreateAccount
                path={routes.createAccountPath}
                onCreateAccount={authenticateUserForPayment}
                universityList={universityList}
              />
              <RWSignUp
                path={routes.rwSignUpPath}
                universityList={universityList}
              />
              <SignIn
                default
                path={routes.signInPath}
                onSignIn={handleSuccessfulSignIn}
              />
              <SignOut path={routes.signOutPath} onSignOut={handleSignOut} />
              <ForgotPassword path={routes.forgotPasswordPath} />
              <PasswordReset
                path={routes.passwordResetPath}
                onReset={handlePasswordReset}
              />
              <EmailConfirmation
                path={routes.emailConfirmationPath}
                onConfirmation={handleEmailConfirmation}
              />
              <Privacy
                path={routes.privacyPath}
                pageContent={pages.find(p => p.title === "Privacy")}
              />
            </Router>
          );
        }}
      </Location>
    );
  }

  if (user.user_type === "rw") {
    const detailPagesContent = pages.filter(p => {
      return [
        "Rec Detail - Guide",
        "Rec Detail - Rec Status",
        "Rec Detail - Resume",
        "Rec Detail - Files",
      ].includes(p.title);
    });
    return (
      <Location>
        {({ location }) => {
          if (location && location.pathname) {
            ReactGA.pageview(location.pathname);
          }
          return (
            <UserSettingsContext.Provider
              value={{
                user,
                updateUser,
                settings,
                updateSettings,
                userUniversityName,
              }}
            >
              <Router>
                <RecRequests
                  default
                  path={routes.recRequestsPath}
                  pageContent={pages.find(p => p.title === "Rec Requests")}
                />
                <RecDetail
                  path={routes.recDetailPath}
                  pagesContent={detailPagesContent}
                />
                <Account path={routes.accountPath} />
                <Settings path={routes.settingsPath} />
                <SignOut path={routes.signOutPath} onSignOut={handleSignOut} />
                <EmailConfirmation
                  path={routes.emailConfirmationPath}
                  onConfirmation={handleEmailConfirmation}
                />
                <Privacy
                  path={routes.privacyPath}
                  pageContent={pages.find(p => p.title === "Privacy")}
                />
              </Router>
            </UserSettingsContext.Provider>
          );
        }}
      </Location>
    );
  }

  return (
    <Location>
      {({ location }) => {
        if (location && location.pathname) {
          ReactGA.pageview(location.pathname);
        }
        return (
          <UserSettingsContext.Provider
            value={{
              user,
              updateUser,
              settings,
              updateSettings,
              userUniversityName,
            }}
          >
            <Router>
              <PaidRoute
                as={Dashboard}
                default
                path={routes.dashboardPath}
                pageContent={pages.find(p => p.title === "Dashboard")}
                universityList={universityList}
              />
              <PaidRoute
                as={RecInvites}
                path={routes.recommendationsPath}
                pageContent={pages.find(p => p.title === "Rec Invites")}
                userUniversityName={userUniversityName}
              />
              <PaidRoute
                as={Resume}
                path={routes.resumePath + "/*"}
                pageContent={pages.find(p => p.title === "Resumé")}
                universityList={universityList}
              />
              <PaidRoute
                as={Files}
                path={routes.filesPath}
                pageContent={pages.find(p => p.title === "Files")}
              />
              <PaidRoute as={Account} path={routes.accountPath} />
              <PaidRoute as={Settings} path={routes.settingsPath} />
              <Payment path={routes.paymentPath} />
              <SignOut path={routes.signOutPath} onSignOut={handleSignOut} />
              <EmailConfirmation
                path={routes.emailConfirmationPath}
                onConfirmation={handleEmailConfirmation}
              />
              <Privacy
                path={routes.privacyPath}
                pageContent={pages.find(p => p.title === "Privacy")}
              />
            </Router>
          </UserSettingsContext.Provider>
        );
      }}
    </Location>
  );
};

export default App;
