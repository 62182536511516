import React from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { AxiosResponse } from "axios";
import agent, { API_BASE_URL } from "../agent";
import { IFile, FileTypes } from "../interfaces";

interface IFileUploadProps {
  render: ({
    handleUpload,
    uploads,
    ready,
  }: {
    handleUpload: Function;
    uploads: any[];
    ready: boolean;
  }) => JSX.Element;
  selectedFileType: FileTypes | "";
  onSuccess(response: AxiosResponse<IFile>): void;
  onError(response: any): void;
}

const FileUpload: React.FC<IFileUploadProps> = props => {
  return (
    <DirectUploadProvider
      directUploadsPath={`${API_BASE_URL}/rails/active_storage/direct_uploads`}
      onSuccess={(signedId: string) => {
        if (props.selectedFileType === "") {
          return props.onError({ message: "Invalid file type" });
        }
        agent.Files.handleAttachment(signedId, props.selectedFileType).then(
          props.onSuccess,
          props.onError
        );
      }}
      render={props.render}
    />
  );
};

export default FileUpload;
