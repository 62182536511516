import React from "react";
import * as yup from "yup";
import { Formik, FormikProps, FormikActions } from "formik";
import {
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Button,
  Row,
  Col,
} from "reactstrap";
import DatePicker from "reactstrap-date-picker";

import { IJob } from "../../interfaces";
import { AxiosPromise } from "axios";
import { formatDate } from "../../utils/formHelpers";

const formSchema = yup.object().shape({
  employer: yup
    .string()
    .label("Employer")
    .required(),
  position: yup
    .string()
    .label("Position")
    .required(),
  date_began: yup
    .date()
    .label("Start Date")
    .required(),
  date_ended: yup
    .date()
    .label("End Date")
    .min(yup.ref("date_began"), "End date must be after start date"),
});

interface IJobFormProps {
  employer: IJob;
  formIsDisabled: boolean;
  onSubmit(employer: IJob): AxiosPromise<IJob>;
  onDelete(employer: IJob): void;
}

const JobForm: React.FC<IJobFormProps> = props => {
  const { employer, formIsDisabled } = props;

  function handleSubmit(values: IJob, actions: FormikActions<IJob>) {
    const modified = {
      ...values,
      date_began: formatDate(values.date_began),
      date_ended: formatDate(values.date_ended),
    };
    props.onSubmit(modified).then(() => {
      actions.setSubmitting(false);
      actions.resetForm(values);
    });
  }

  return (
    <Formik
      initialValues={{
        ...employer,
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<IJob>) => (
        <Form onSubmit={formikBag.handleSubmit}>
          <FormGroup>
            <Label>
              Employer <span className="text-danger">*</span>
            </Label>
            <Input
              name="employer"
              value={formikBag.values.employer || ""}
              onChange={formikBag.handleChange}
              invalid={
                !!(formikBag.errors.employer && formikBag.touched.employer)
              }
              disabled={formIsDisabled}
            />
            <FormFeedback>{formikBag.errors.employer}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>
              Position <span className="text-danger">*</span>
            </Label>
            <Input
              name="position"
              value={formikBag.values.position || ""}
              onChange={formikBag.handleChange}
              invalid={
                !!(formikBag.errors.position && formikBag.touched.position)
              }
              disabled={formIsDisabled}
            />
            <FormFeedback>{formikBag.errors.position}</FormFeedback>
          </FormGroup>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label>
                  Start Date <span className="text-danger">*</span>
                </Label>
                <DatePicker
                  showClearButton={false}
                  value={formikBag.values.date_began || ""}
                  onChange={(v: string) =>
                    formikBag.setFieldValue("date_began", v)
                  }
                  invalid={
                    !!(
                      formikBag.errors.date_began &&
                      formikBag.touched.date_began
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.date_began}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label>End Date</Label>
                <DatePicker
                  showClearButton={false}
                  onChange={(v: string) =>
                    formikBag.setFieldValue("date_ended", v === null ? "" : v)
                  }
                  value={formikBag.values.date_ended || ""}
                  invalid={
                    !!(
                      formikBag.errors.date_ended &&
                      formikBag.touched.date_ended
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.date_ended}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <div className="d-flex justify-content-between">
            <Button
              className="mr-2"
              onClick={() => props.onDelete(employer)}
              disabled={formIsDisabled}
            >
              Delete
            </Button>
            <Button
              color="primary"
              type="submit"
              className={formikBag.dirty ? "d-block" : "d-none"}
              disabled={formIsDisabled}
            >
              Save
            </Button>
          </div>
          <hr />
        </Form>
      )}
    />
  );
};

export default JobForm;
