import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";

import agent from "../agent";
import dayjs from "dayjs";
import { INotification } from "../interfaces";

const Notifications = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  useEffect(() => {
    agent.Notifications.getAll().then(res => {
      setNotifications(
        res.data.sort((a, b) =>
          dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1
        )
      );
    });
  }, []);

  return (
    <Card className="border-0">
      <CardHeader>
        Notifications <span>({notifications.length})</span>
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          {notifications.map(n => {
            const localTimestamp = dayjs
              .utc(n.created_at)
              .isBefore(dayjs().subtract(6, "day"))
              ? dayjs
                  .utc(n.created_at)
                  .local()
                  .format("MM/DD/YY")
              : dayjs
                  .utc(n.created_at)
                  .local()
                  .from(dayjs(), true);
            return (
              <ListGroupItem action key={n.id}>
                <Row>
                  <Col xs="4">
                    <p className="mb-0 text-muted">
                      <small>{localTimestamp}</small>
                    </p>
                  </Col>
                  <Col xs="8">
                    <p className="mb-0">{n.description}</p>
                  </Col>
                </Row>
              </ListGroupItem>
            );
          })}
          {notifications.length === 0 && (
            <>
              <ListGroupItem action>
                <p className="mb-0 text-muted">No notifications</p>
              </ListGroupItem>
              <ListGroupItem>
                <p />
              </ListGroupItem>
            </>
          )}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

export default Notifications;
