import React, { useState } from "react";
import { Link } from "@reach/router";
import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";

import * as routes from "../routes";

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import { IUserRegistration, IUniversity, IApiResult } from "../interfaces";
import PasswordPeekInput from "./PasswordPeekInput";

export interface ICreateAccountFormValues extends IUserRegistration {
  password_confirm: string;
}

function createFormSchema(isRWAccount: boolean | undefined) {
  const formSchema = {
    email: yup
      .string()
      .label("Email")
      .email()
      .required(),
    first_name: yup
      .string()
      .label("First name")
      .required(),
    last_name: yup
      .string()
      .label("First name")
      .required(),
    university_id: yup
      .number()
      .label("College/University")
      .required(),
    password: yup
      .string()
      .min(6)
      .matches(
        /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{6,})/,
        "Password must contain at least one number"
      )
      .label("Password")
      .required(),
    password_confirm: yup
      .string()
      .label("Confirm password")
      .oneOf([yup.ref("password"), null], "Passwords do not match")
      .required(),
  };
  if (isRWAccount) {
    formSchema.university_id = yup.number().nullable();
  }
  return yup.object().shape(formSchema);
}

interface ICreateAccountFormProps {
  universityList: IUniversity[];
  isRWAccount?: boolean;
  onSubmit(values: ICreateAccountFormValues): Promise<IApiResult>;
}
const CreateAccountForm: React.FC<ICreateAccountFormProps> = props => {
  const [errorMessage, setErrorMessage] = useState("");

  async function handleSubmit(
    values: ICreateAccountFormValues,
    actions: FormikActions<ICreateAccountFormValues>
  ) {
    setErrorMessage("");
    props.onSubmit(values).then(res => {
      console.log("CreateAccountForm onSubmit res:", res);
      if (res.error && res.message) {
        setErrorMessage(res.message);
        actions.setSubmitting(false);
      }
    });
  }

  return (
    <Formik
      initialValues={{
        email: "",
        first_name: "",
        last_name: "",
        university_id: props.isRWAccount ? null : 0,
        password: "",
        password_confirm: "",
        user_type: props.isRWAccount ? "rw" : "pnm",
      }}
      validationSchema={createFormSchema(props.isRWAccount)}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<ICreateAccountFormValues>) => (
        <Form onSubmit={formikBag.handleSubmit}>
          <Row>
            <Col sm="6">
              <Card className="rh-bg-teal border-0 h-100">
                <CardBody>
                  <h2>Personal Information</h2>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          <span className="text-danger">*</span> First Name
                        </Label>
                        <Input
                          name="first_name"
                          onChange={formikBag.handleChange}
                          invalid={
                            !!(
                              formikBag.errors.first_name &&
                              formikBag.touched.first_name
                            )
                          }
                        />
                        <FormFeedback>
                          {formikBag.errors.first_name}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>
                          <span className="text-danger">*</span> Last Name
                        </Label>
                        <Input
                          name="last_name"
                          onChange={formikBag.handleChange}
                          invalid={
                            !!(
                              formikBag.errors.last_name &&
                              formikBag.touched.last_name
                            )
                          }
                        />
                        <FormFeedback>
                          {formikBag.errors.last_name}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  {!props.isRWAccount && (
                    <>
                      <FormGroup>
                        <Label>
                          <span className="text-danger">*</span>{" "}
                          {props.isRWAccount
                            ? "Which College/University did you attend?"
                            : "The College/University you are planning on attending"}
                        </Label>
                        <Input
                          type="select"
                          name="university_id"
                          onChange={formikBag.handleChange}
                          invalid={
                            !!(
                              formikBag.errors.university_id &&
                              formikBag.touched.university_id
                            )
                          }
                        >
                          <option />
                          {props.universityList.map(option => (
                            <option key={option.id} value={option.id}>
                              {option.friendly_name || option.name}
                            </option>
                          ))}
                        </Input>
                        <FormFeedback>
                          {formikBag.errors.university_id}
                        </FormFeedback>
                      </FormGroup>
                      {!props.isRWAccount && (
                        <Alert color="danger">
                          Before you create your account, double check that you
                          have selected the correct University and Campus.
                        </Alert>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card className="rh-bg-teal border-0 h-100">
                <CardBody>
                  <h2>Sign In Credentials</h2>
                  <FormGroup>
                    <Label>
                      <span className="text-danger">*</span> Email Address
                    </Label>
                    <Input
                      name="email"
                      type="email"
                      autoComplete="email"
                      onChange={formikBag.handleChange}
                      invalid={
                        !!(formikBag.errors.email && formikBag.touched.email)
                      }
                    />
                    <FormFeedback>{formikBag.errors.email}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <span className="text-danger">*</span> Password
                    </Label>
                    <PasswordPeekInput
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      onChange={formikBag.handleChange}
                      error={formikBag.errors.password}
                      invalid={
                        !!(
                          formikBag.errors.password &&
                          formikBag.touched.password
                        )
                      }
                    />
                    <FormText>
                      Must be at least six or more characters and at least 1
                      number.
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <span className="text-danger">*</span> Confirm Password
                    </Label>
                    <PasswordPeekInput
                      name="password_confirm"
                      type="password"
                      autoComplete="new-password"
                      onChange={formikBag.handleChange}
                      error={formikBag.errors.password_confirm}
                      invalid={
                        !!(
                          formikBag.errors.password_confirm &&
                          formikBag.touched.password_confirm
                        )
                      }
                    />
                  </FormGroup>
                  <div className="text-center">
                    <p>
                      By continuing, you agree to RECHUB's{" "}
                      <Link to={routes.toPrivacyPath}>
                        Privacy Policy &amp; Terms of Use.
                      </Link>
                    </p>
                    <Alert
                      isOpen={!!errorMessage}
                      color="danger"
                      className="my-2"
                    >
                      {errorMessage}
                    </Alert>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={formikBag.isSubmitting}
                    >
                      Create Account
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default CreateAccountForm;
