import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import agent from "../agent";
import iconLogoC from "../images/icon-logo-c.svg";
import Layout from "../components/Layout";

const formSchema = yup.object().shape({
  email: yup
    .string()
    .label("Email")
    .email()
    .required(),
});

interface IForgotPasswordFormValues {
  email: string;
}

const ForgotPassword: React.FC<RouteComponentProps> = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showResetMessage, setShowResetMessage] = useState<boolean>(false);
  function handleSubmit(
    values: IForgotPasswordFormValues,
    actions: FormikActions<IForgotPasswordFormValues>
  ) {
    agent.Auth.resetPassword(values.email)
      .then(res => {
        actions.setSubmitting(false);
        setShowResetMessage(true);
      })
      .catch(err => {
        actions.setSubmitting(false);
        if (err && err.response) {
          setErrorMessage(err.response.data.errors[0].detail);
        } else {
          setErrorMessage("There was a problem sending the reset email.");
        }
      });
  }

  return (
    <Layout>
      <Container className="pt-3">
        <div className="text-center">
          <img src={iconLogoC} alt="Rec Hub logo icon" className="mb-3" />
          <h1 className="text-center mb-3">Forgot Password</h1>
        </div>
        <Row>
          <Col sm={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
            <Card>
              <CardBody>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={formSchema}
                  onSubmit={handleSubmit}
                  render={(
                    formikBag: FormikProps<IForgotPasswordFormValues>
                  ) => (
                    <Form onSubmit={formikBag.handleSubmit}>
                      <FormGroup>
                        <Label>Email</Label>
                        <Input
                          name="email"
                          type="email"
                          onChange={formikBag.handleChange}
                          placeholder="Email"
                          invalid={
                            !!(
                              formikBag.errors.email && formikBag.touched.email
                            )
                          }
                        />
                        <FormFeedback>{formikBag.errors.email}</FormFeedback>
                      </FormGroup>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={formikBag.isSubmitting}
                      >
                        Reset Password
                      </Button>
                      {errorMessage && (
                        <Alert color="danger" className="mt-3">
                          {errorMessage}
                        </Alert>
                      )}
                      {showResetMessage && (
                        <Alert color="success" className="mt-3">
                          Please check your email to continue the password
                          reset.
                        </Alert>
                      )}
                    </Form>
                  )}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ForgotPassword;
