import React, { useState, useEffect, useContext } from "react";
import { Link, RouteComponentProps, navigate } from "@reach/router";
import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Button,
  CustomInput,
} from "reactstrap";
import InputMask from "react-input-mask";
import DatePicker from "reactstrap-date-picker";

import * as routes from "../../routes";

import UserSettingsContext from "../../context/user-settings-context";
import { IProfile } from "../../interfaces";
import { formatDate } from "../../utils/formHelpers";

const formSchema = yup.object().shape({
  name: yup
    .string()
    .label("Full name")
    .required()
    .nullable(),
  preferred_name: yup
    .string()
    .label("Preferred name")
    .nullable(),
  date_of_birth: yup
    .string()
    .label("Date of Birth")
    .required()
    .nullable(),
  street_address: yup
    .string()
    .label("Street address")
    .required()
    .nullable(),
  mobile_number: yup
    .string()
    .label("Mobile phone")
    .required()
    .nullable(),
  facebook_url: yup
    .string()
    .label("Facebook URL")
    .nullable(),
  instagram_name: yup
    .string()
    .label("Instagram username")
    .nullable(),
  snapchat_name: yup
    .string()
    .label("Snapchat username")
    .nullable(),
  twitter_name: yup
    .string()
    .label("Twitter username")
    .nullable(),
});

interface IEditPersonalProps extends RouteComponentProps {
  profile: IProfile;
  onSubmit(profile: IProfile): Promise<void>;
  formIsDisabled: boolean;
}

const EditPersonal: React.FC<IEditPersonalProps> = props => {
  const { user } = useContext(UserSettingsContext);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }
  }, []);

  const { profile, formIsDisabled } = props;
  function handleSubmit(values: IProfile, actions: FormikActions<IProfile>) {
    const modified = {
      ...profile,
      ...values,
      date_of_birth: formatDate(values.date_of_birth),
    };
    props.onSubmit(modified).then(() => {
      navigate(routes.toResumeEditEducation);
    });
  }

  const [showFacebook, setShowFacebook] = useState(!!profile.facebook_url);
  const [showInstagram, setShowInstagram] = useState(!!profile.instagram_name);
  const [showSnapchat, setShowSnapchat] = useState(!!profile.snapchat_name);
  const [showTwitter, setShowTwitter] = useState(!!profile.twitter_name);

  return (
    <Formik
      initialValues={{
        ...profile,
        name: profile.name || `${user.first_name} ${user.last_name}`,
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<IProfile>) => (
        <Form onSubmit={formikBag.handleSubmit}>
          <Card className="rh-bg-teal border-0 mb-2">
            <CardBody>
              <FormGroup>
                <Label>
                  Full name <span className="text-danger">*</span>
                </Label>
                <Input
                  name="name"
                  value={formikBag.values.name || ""}
                  onChange={formikBag.handleChange}
                  invalid={!!(formikBag.errors.name && formikBag.touched.name)}
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.name}</FormFeedback>
              </FormGroup>
              <Row>
                <Col sm="7">
                  <FormGroup>
                    <Label>Preferred Name (optional)</Label>
                    <Input
                      name="preferred_name"
                      value={formikBag.values.preferred_name || ""}
                      onChange={formikBag.handleChange}
                      invalid={
                        !!(
                          formikBag.errors.preferred_name &&
                          formikBag.touched.preferred_name
                        )
                      }
                      disabled={formIsDisabled}
                    />
                    <FormFeedback>
                      {formikBag.errors.preferred_name}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm="5">
                  <FormGroup>
                    <Label>
                      Date of Birth <span className="text-danger">*</span>
                    </Label>
                    <DatePicker
                      showClearButton={false}
                      name="date_of_birth"
                      value={formikBag.values.date_of_birth || ""}
                      onChange={(v: string) =>
                        formikBag.setFieldValue("date_of_birth", v)
                      }
                      invalid={
                        !!(
                          formikBag.errors.date_of_birth &&
                          formikBag.touched.date_of_birth
                        )
                      }
                      disabled={formIsDisabled}
                    />
                    {formikBag.errors.date_of_birth && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {formikBag.errors.date_of_birth}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label>
                  Current Street Address, City, State, ZIP{" "}
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  name="street_address"
                  value={formikBag.values.street_address || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.street_address &&
                      formikBag.touched.street_address
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.street_address}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>
                  Mobile Phone Number <span className="text-danger">*</span>
                </Label>
                <Input
                  name="mobile_number"
                  value={formikBag.values.mobile_number || ""}
                  onChange={formikBag.handleChange}
                  placeholder="(___) ___ - ____"
                  type="tel"
                  maskChar=" "
                  mask={"(999) 999-9999"}
                  tag={InputMask}
                  invalid={
                    !!(
                      formikBag.errors.mobile_number &&
                      formikBag.touched.mobile_number
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.mobile_number}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="facebookPageCheckbox"
                  label="Facebook Page"
                  checked={
                    showFacebook ||
                    !!(
                      formikBag.values.facebook_url &&
                      formikBag.values.facebook_url.length > 0
                    )
                  }
                  onChange={() => {
                    formikBag.setFieldValue("facebook_url", "");
                    setShowFacebook(!showFacebook);
                  }}
                  disabled={formIsDisabled}
                />
              </FormGroup>
              <FormGroup className={showFacebook ? "d-block" : "d-none"}>
                <Label>Facebook Page</Label>
                <Input
                  name="facebook_url"
                  value={formikBag.values.facebook_url || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.facebook_url &&
                      formikBag.touched.facebook_url
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.facebook_url}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="instagramCheckbox"
                  label="Instagram Username"
                  checked={
                    showInstagram ||
                    !!(
                      formikBag.values.instagram_name &&
                      formikBag.values.instagram_name.length > 0
                    )
                  }
                  onChange={() => {
                    formikBag.setFieldValue("instagram_name", "");
                    setShowInstagram(!showInstagram);
                  }}
                  disabled={formIsDisabled}
                />
              </FormGroup>
              <FormGroup className={showInstagram ? "d-block" : "d-none"}>
                <Label>Instagram Username</Label>
                <Input
                  name="instagram_name"
                  value={formikBag.values.instagram_name || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.instagram_name &&
                      formikBag.touched.instagram_name
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.instagram_name}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="snapchatCheckbox"
                  label="Snapchat Username"
                  checked={
                    showSnapchat ||
                    !!(
                      formikBag.values.snapchat_name &&
                      formikBag.values.snapchat_name.length > 0
                    )
                  }
                  onChange={() => {
                    formikBag.setFieldValue("snapchat_name", "");
                    setShowSnapchat(!showSnapchat);
                  }}
                  disabled={formIsDisabled}
                />
              </FormGroup>
              <FormGroup className={showSnapchat ? "d-block" : "d-none"}>
                <Label>Snapchat Username</Label>
                <Input
                  name="snapchat_name"
                  value={formikBag.values.snapchat_name || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.snapchat_name &&
                      formikBag.touched.snapchat_name
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.snapchat_name}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="twitterCheckbox"
                  label="Twitter Username"
                  checked={
                    showTwitter ||
                    !!(
                      formikBag.values.twitter_name &&
                      formikBag.values.twitter_name.length > 0
                    )
                  }
                  onChange={() => {
                    formikBag.setFieldValue("twitter_name", "");
                    setShowTwitter(!showTwitter);
                  }}
                  disabled={formIsDisabled}
                />
              </FormGroup>
              <FormGroup className={showTwitter ? "d-block" : "d-none"}>
                <Label>Twitter Username</Label>
                <Input
                  name="twitter_name"
                  value={formikBag.values.twitter_name || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.twitter_name &&
                      formikBag.touched.twitter_name
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.twitter_name}</FormFeedback>
              </FormGroup>
            </CardBody>
            <CardBody className="text-right">
              <Button type="submit" color="primary">
                Save and Continue
              </Button>
            </CardBody>
          </Card>
          <Link to={routes.toResumeEditEducation}>
            Skip and come back to later
          </Link>
        </Form>
      )}
    />
  );
};

export default EditPersonal;
