import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, navigate } from "@reach/router";
import { Card, CardBody, FormGroup, Input, Label, Button } from "reactstrap";

import * as routes from "../../routes";

import { IProfile, IAffiliation } from "../../interfaces";
import GreekAffiliationForm from "./GreekAffiliationForm";
import agent from "../../agent";

interface IEditGreekAffiliationsProps extends RouteComponentProps {
  profile: IProfile;
  formIsDisabled: boolean;
  onUpdateProfile(profile: IProfile): void;
  onSubmit(profile: IProfile): Promise<void>;
}

const emptyAffiliation: IAffiliation = {
  campus: "",
  city: "",
  date_began: "",
  date_ended: "",
  email: "",
  key: new Date().getMilliseconds(),
  mobile_number: "",
  name: "",
  relationship: "",
  sorority: "",
  state: "",
};

const EditGreekAffiliations: React.FC<IEditGreekAffiliationsProps> = props => {
  const { profile, formIsDisabled } = props;

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }
  }, []);

  const [optOut, setOptOut] = useState(profile.opt_out_greek_affiliations);
  function toggleOptOut() {
    if (optOut) {
      setOptOut(false);
      addBlankAffiliation();
    } else {
      setOptOut(true);
      setAffiliations(null);
    }
  }

  function saveAndContinue() {
    agent.Profile.update({
      ...profile,
      opt_out_greek_affiliations: optOut,
    }).then(res => {
      props.onUpdateProfile(res.data);
      navigate(routes.toResumeEditActivities);
    });
  }

  const [affiliations, setAffiliations] = useState<IAffiliation[] | null>(null);

  useEffect(() => {
    fetchGreekAffiliations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchGreekAffiliations() {
    agent.GreekAffiliations.getAll().then(res => {
      if (res.data && res.data.length > 0) {
        setAffiliations(res.data);
      } else if (
        res.data &&
        res.data.length === 0 &&
        !profile.opt_out_greek_affiliations
      ) {
        addBlankAffiliation();
      }
    });
  }

  function addBlankAffiliation() {
    if (affiliations) {
      setAffiliations([
        ...affiliations,
        {
          ...emptyAffiliation,
          key: affiliations.length,
        },
      ]);
    } else {
      setAffiliations([emptyAffiliation]);
    }
  }

  function deleteAffiliation(affiliationToDelete: IAffiliation) {
    if (affiliationToDelete.id) {
      agent.GreekAffiliations.delete(affiliationToDelete.id).then(res => {
        if (affiliations) {
          setAffiliations(
            affiliations.filter(a => a.id !== affiliationToDelete.id)
          );
        }
      });
    } else if (affiliationToDelete.key) {
      if (affiliations) {
        setAffiliations(
          affiliations.filter(a => a.key !== affiliationToDelete.key)
        );
      }
    }
  }

  function saveAffiliation(updatedAffiliation: IAffiliation) {
    if (updatedAffiliation.id) {
      return agent.GreekAffiliations.update(
        updatedAffiliation.id,
        updatedAffiliation
      );
    } else {
      return agent.GreekAffiliations.create(updatedAffiliation);
    }
  }

  return (
    <>
      <Card className="rh-bg-teal border-0 mb-2">
        <CardBody className="pb-1">
          <p className="mb-2">
            Please list any relatives and family remembers who were initiated
            into a National Panhellenic Sorority.
          </p>
          <FormGroup check className="mr-2">
            <Label check>
              <Input
                type="checkbox"
                name="optOut"
                checked={optOut === true}
                onChange={toggleOptOut}
                disabled={formIsDisabled}
              />{" "}
              I have no Greek Affiliations
            </Label>
          </FormGroup>
        </CardBody>
        {!optOut && affiliations && (
          <CardBody>
            {affiliations.map((a, index) => (
              <GreekAffiliationForm
                key={index}
                affiliation={a}
                onSubmit={saveAffiliation}
                onDelete={deleteAffiliation}
                formIsDisabled={formIsDisabled}
              />
            ))}
            <Button onClick={addBlankAffiliation} disabled={formIsDisabled}>
              <i className="fas fa-plus mr-2" /> Add Another
            </Button>
          </CardBody>
        )}
        <CardBody className="d-flex justify-content-between">
          <Button
            tag={Link}
            to={routes.toResumeEditFamily}
            color="link"
            className="btn-slim"
          >
            <i className="fas fa-chevron-left mr-2" /> Back
          </Button>
          <Button onClick={saveAndContinue} color="primary">
            Continue
          </Button>
        </CardBody>
      </Card>
      <Link to={routes.toResumeEditActivities}>
        Skip and come back to later
      </Link>
    </>
  );
};

export default EditGreekAffiliations;
