import React, { useEffect } from "react";
import { RouteComponentProps } from "@reach/router";

interface ISignOutProps extends RouteComponentProps {
  onSignOut(): void;
}

const SignOut: React.FC<ISignOutProps> = props => {
  useEffect(() => {
    console.log("Signing out...");
    props.onSignOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default SignOut;
