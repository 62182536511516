import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps } from "@reach/router";
import { Container } from "reactstrap";

import agent from "../agent";
import { signUpSteps } from "../constants";
import { IBraintreeRequestPaymentResponse } from "../interfaces";

import UserSettingsContext from "../context/user-settings-context";

import Layout from "../components/Layout";
import WizardSteps from "../components/WizardSteps";
import PaymentForm from "../components/PaymentForm";
import SignUpComplete from "../components/SignUpComplete";

import iconLogoC from "../images/icon-logo-c.svg";

const Payment: React.FC<RouteComponentProps> = props => {
  const { user } = useContext(UserSettingsContext);
  const [token, setToken] = useState<string | null>(null);
  const [price, setPrice] = useState<string | number | null>(null);
  useEffect(() => {
    getToken();
  }, []);

  function getToken() {
    agent.Billing.getToken().then(res => {
      if (res.data) {
        setToken(res.data.token);
        setPrice(res.data.price);
      }
    });
  }

  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  function handlePaymentSuccess(payload: IBraintreeRequestPaymentResponse) {
    agent.Billing.checkout(payload.nonce)
      .then(res => {
        console.log("payment success", res);
        setShowSuccess(true);
      })
      .catch(err => {
        console.log("payment failed", err.response.data);
        setErrorMessage(err.response.data.message);
      });
  }

  return (
    <Layout>
      <Container className="pt-3">
        {user.paid ? (
          <div className="text-center">
            <img src={iconLogoC} alt="Rec Hub logo icon" className="mb-3" />
            <h1 className="text-center mb-3">You've already paid!</h1>
            <SignUpComplete />
          </div>
        ) : (
          <>
            <div className="text-center">
              <img src={iconLogoC} alt="Rec Hub logo icon" className="mb-3" />
              <h1 className="text-center mb-3">
                {showSuccess ? "Congratulations!" : "Sign Up"}
              </h1>
            </div>
            <WizardSteps
              className="mb-4"
              currentStepIndex={showSuccess ? 2 : 1}
              steps={signUpSteps}
            />
            {token && !showSuccess && (
              <PaymentForm
                onRequestPayment={handlePaymentSuccess}
                errorMessage={errorMessage}
                token={token}
                price={price}
                user={user}
              />
            )}
            {showSuccess && <SignUpComplete />}
          </>
        )}
      </Container>
    </Layout>
  );
};

export default Payment;
