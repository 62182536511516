import React from "react";

import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";

import {
  Row,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { IAccountSettings } from "../interfaces";
import { AxiosPromise } from "axios";

export interface IPrimaryContactFormValues {
  first_name: string;
  last_name: string;
  email: string;
}

const formSchema = yup.object().shape({
  first_name: yup
    .string()
    .label("First Name")
    .required(),
  last_name: yup
    .string()
    .label("Last Name")
    .required(),
  email: yup
    .string()
    .email()
    .label("Email")
    .required(),
});

interface IPrimaryContactFormProps {
  className?: string;
  isOpen: boolean;
  accountData: IAccountSettings;
  onClose(): void;
  onSave(accountData: IAccountSettings): AxiosPromise<IAccountSettings>;
}

const PrimaryContactFormModal: React.FC<IPrimaryContactFormProps> = props => {
  function handleSubmit(
    values: IPrimaryContactFormValues,
    actions: FormikActions<IPrimaryContactFormValues>
  ) {
    const accountData = {
      ...props.accountData,
      ...values,
    };
    props.onSave(accountData).then(() => {
      actions.setSubmitting(false);
      props.onClose();
    });
  }

  return (
    <Formik
      initialValues={{
        first_name: props.accountData.first_name || "",
        last_name: props.accountData.last_name || "",
        email: props.accountData.email || "",
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<IPrimaryContactFormValues>) => (
        <Modal
          isOpen={props.isOpen}
          toggle={props.onClose}
          className={props.className}
        >
          <Form onSubmit={formikBag.handleSubmit}>
            <ModalHeader toggle={props.onClose}>Primary Contact</ModalHeader>
            <ModalBody className="rh-bg-teal">
              <p>Provide your information in the text fields below.</p>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>First Name</Label>
                    <Input
                      name="first_name"
                      value={formikBag.values.first_name}
                      onChange={formikBag.handleChange}
                      invalid={
                        !!(
                          formikBag.errors.first_name &&
                          formikBag.touched.first_name
                        )
                      }
                    />
                    <FormFeedback>{formikBag.errors.first_name}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Last Name (account holder)</Label>
                    <Input
                      name="last_name"
                      value={formikBag.values.last_name}
                      onChange={formikBag.handleChange}
                      invalid={
                        !!(
                          formikBag.errors.last_name &&
                          formikBag.touched.last_name
                        )
                      }
                    />
                    <FormFeedback>{formikBag.errors.last_name}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label>Email Address</Label>
                <Input
                  name="email"
                  value={formikBag.values.email}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(formikBag.errors.email && formikBag.touched.email)
                  }
                />
                <FormFeedback>{formikBag.errors.email}</FormFeedback>
              </FormGroup>
            </ModalBody>
            <ModalFooter className="rh-bg-teal text-right">
              <Button disabled={formikBag.isSubmitting} onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={formikBag.isSubmitting}
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    />
  );
};

export default PrimaryContactFormModal;
