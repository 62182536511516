import React from "react";
import ReactMarkdown from "react-markdown";
import { Card, CardBody } from "reactstrap";

import iconTip from "../images/icon-tip.svg";

interface ITipProps {
  className?: string;
  text: string;
}

const Tip: React.FC<ITipProps> = props => {
  return (
    <Card className={props.className || ""}>
      <CardBody className="d-flex">
        <div className="mr-2">
          <img src={iconTip} alt="lightbulb" />
        </div>
        <div className="clearfix">
          <span className="font-weight-bold text-primary float-left mr-2">
            Tip:
          </span>
          <ReactMarkdown source={props.text} />
        </div>
      </CardBody>
    </Card>
  );
};

export default Tip;
