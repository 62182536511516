import React, { useState } from "react";
import { Alert, Label, Input } from "reactstrap";
import FileUpload from "../FileUpload";
import { FileTypes } from "../../interfaces";
import Spinner from "reactstrap/lib/Spinner";
import { resizeImage, blobToFile } from "../../utils/genericUtils";
import { PHOTO_RESIZE_DIMENSIONS } from "../../constants";

interface IEditPhoto {
  photoUrl: string | null;
  reloadPhoto(): void;
}

const EditPhoto: React.FC<IEditPhoto> = props => {
  const [photoUploadError, setPhotoUploadError] = useState<string | null>();

  return (
    <>
      {props.photoUrl ? (
        <img
          src={`${props.photoUrl}`}
          alt="Resume profile "
          className="mx-auto rh-bg-teal rounded-circle d-flex align-items-center justify-content-center mb-3"
          style={{ width: "250px", height: "250px", objectFit: "cover" }}
        />
      ) : (
        <div
          className="mx-auto rh-bg-teal rounded-circle d-flex align-items-center justify-content-center mb-3"
          style={{ width: "250px", height: "250px" }}
        />
      )}
      <FileUpload
        selectedFileType={FileTypes.profile_photo}
        onError={() => setPhotoUploadError("Error uploading photo")}
        onSuccess={() => {
          setPhotoUploadError(null);
          props.reloadPhoto();
        }}
        render={({ ready, handleUpload, uploads }) => (
          <Label
            className={`btn ${ready ? "bg-light" : ""}`}
            disabled={!ready}
            onClick={e => e.stopPropagation()}
            for="fileUpload"
          >
            {uploads.length ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />

                <span className="ml-2">
                  Uploading:{" "}
                  {uploads.map(upload =>
                    !upload.progress ? 0 : Math.round(upload.progress)
                  )}
                  %
                </span>
              </>
            ) : (
              <>
                <i className="fas fa-upload mr-2" /> Upload Photo{" "}
              </>
            )}

            <Input
              hidden
              name="fileUpload"
              id="fileUpload"
              type="file"
              disabled={!ready}
              onChange={e => {
                const {
                  currentTarget: { files },
                } = e;
                e.stopPropagation();
                if (!files) {
                  return;
                }

                console.log("orig file size:", files[0].size);
                resizeImage({
                  file: files[0],
                  maxSize: PHOTO_RESIZE_DIMENSIONS,
                }).then((r: any) => {
                  const resized = blobToFile(r, files[0].name);

                  console.log("resized file size:", resized.size);
                  handleUpload([resized]);
                });
              }}
            />

            <Input hidden type="file" />
          </Label>
        )}
      />

      {photoUploadError && <Alert color="danger">{photoUploadError}</Alert>}
    </>
  );
};

export default EditPhoto;
