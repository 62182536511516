import React from "react";
import dayjs from "dayjs";
import { Badge } from "reactstrap";

import { IRecInvite, WriterStatus } from "../interfaces";

const InviteBadge: React.FC<{
  invite: IRecInvite;
}> = props => {
  const { invite } = props;

  if (
    invite.enabled &&
    invite.created_at &&
    !invite.sent_at &&
    invite.updated_at === invite.created_at
  ) {
    return (
      <>
        <Badge
          color="success"
          className="py-1 px-2 mr-2"
          style={{ width: "43px" }}
        >
          New
        </Badge>
        <small>
          Activated {dayjs(invite.created_at).format("MMM D, h:mm a")}
        </small>
      </>
    );
  } else if (
    invite.enabled &&
    invite.created_at &&
    !invite.sent_at &&
    invite.updated_at &&
    invite.updated_at !== invite.created_at
  ) {
    return (
      <>
        <Badge
          color="info"
          className="py-1 px-2 mr-2"
          style={{ width: "43px" }}
          title="Invitation started but not sent to rec writer"
        >
          Draft
        </Badge>
        <small>
          Updated {dayjs(invite.updated_at).format("MMM D, h:mm a")}
        </small>
      </>
    );
  } else if (
    invite.enabled &&
    invite.sent_at &&
    invite.writer_status === WriterStatus.UNOPENED
  ) {
    return (
      <>
        <Badge
          color="warning"
          className="py-1 px-2 mr-2"
          title="Invitation has been sent to rec writer but not accepted"
        >
          Invite Sent
        </Badge>
        <small>{dayjs(invite.sent_at).format("MMM D, h:mm a")}</small>
      </>
    );
  } else if (
    invite.enabled &&
    invite.writer_status === WriterStatus.IN_PROGRESS
  ) {
    return (
      <>
        <Badge
          color="warning"
          className="py-1 px-2 mr-2"
          title="Invitation accepted and recommendation underway"
        >
          Rec In Progress
        </Badge>
      </>
    );
  } else if (invite.enabled && invite.writer_status === WriterStatus.SENT) {
    return (
      <>
        <Badge
          color="success"
          className="py-1 px-2 mr-2"
          title="Recommendation is complete and has been sent to the sorority"
        >
          <i className="fas fa-thumbs-up" /> Rec Sent to Sorority
        </Badge>
      </>
    );
  } else if (
    invite.enabled &&
    invite.writer_status === WriterStatus.BAD_EMAIL
  ) {
    return (
      <>
        <Badge
          color="danger"
          className="py-1 px-2 mr-2"
          title="Invitation could not be sent due to bad email address"
        >
          Bad Email
        </Badge>
      </>
    );
  } else {
    return null;
  }
};

export default InviteBadge;
