export interface IApiError {
  code: string;
  detail: object;
  status: string;
  title: string;
}
export interface IApiResult {
  error?: boolean;
  message?: string;
  data?: any;
}

export interface IContentfulPage {
  title: string;
  instructions: string;
  secondaryInstructions: string;
  tips: string[];
}

export interface IAccountSettings {
  amount_paid: string;
  display_name: string | null;
  email: string;
  email_frequency: string;
  first_name: string;
  full_name: string | null;
  last_name: string;
  mobile_number: string | null;
  notify_primary: boolean;
  notify_secondary: boolean;
  secondary_display_name: string | null;
  secondary_email: string | null;
  secondary_full_name: string | null;
  secondary_mobile_number: string | null;
  send_notification_emails: boolean;
  user_type: string;
}

export interface IUser {
  confirmed_at: string | null;
  email: string;
  first_name: string | null;
  id: number;
  last_name: string | null;
  paid: boolean | null;
  university_id: number;
  user_type: string;
  payment_info: {
    id: number;
    amount: string;
    transaction_id: string;
    user_id: number;
    created_at: string;
    updated_at: string;
  } | null;
}
export interface IUserRegistration {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  university_id: number | null;
  user_type: "rw" | "pnm";
}

export interface IDashboardStats {
  files: {
    bio: boolean;
    photo: boolean;
    transcript: boolean;
    profile_photo: boolean;
  };
  rec_invites: {
    rec_invites_sent: number;
    recs_in_progress: number;
    recs_sent_to_sorority: number;
    total_invites: number;
  };
  resume: {
    total: number;
    complete: number;
  };
}

export interface IBraintreeRequestPaymentResponse {
  binData: {
    commercial: string;
    countryOfIssuance: string;
    debit: string;
    durbinRegulated: string;
    healthcare: string;
    issuingBank: string;
    payroll: string;
    prepaid: string;
    productId: string;
  };
  description: string;
  details: {
    cardType: string;
    lastFour: string;
    lastTwo: string;
  };
  nonce: string;
  type: string;
}

export interface IStep {
  name: string;
  to: string;
  isComplete: boolean;
}

export interface IProfile {
  act_score: number | string | null;
  complete: boolean | null;
  created_at: string;
  curriculum: string[] | null;
  date_of_birth: string | null;
  entering_as: string | null;
  facebook_url: string | null;
  father_college: string | null;
  father_degree: string | null;
  father_name: string | null;
  father_profession: string | null;
  hs_address: string | null;
  hs_class_rank: number | string | null;
  hs_class_size: number | string | null;
  hs_gpa: string | null;
  hs_gpa_scale: string | null;
  hs_name: string | null;
  id: number;
  instagram_name: string | null;
  intended_major: string | null;
  mobile_number: string | null;
  mother_college: string | null;
  mother_degree: string | null;
  mother_maiden_name: string | null;
  mother_name: string | null;
  mother_profession: string | null;
  name: string | null;
  opt_out_activities: boolean;
  opt_out_awards: boolean;
  opt_out_community_activities: boolean;
  opt_out_greek_affiliations: boolean;
  opt_out_jobs: boolean;
  preferred_name: string | null;
  profile_photo_url: string | null;
  resume_url: string | null;
  sat_score: number | string | null;
  snapchat_name: string | null;
  street_address: string | null;
  transfer_from: string | null;
  twitter_name: string | null;
  updated_at: string;
  pnm_email: string | null;
  university?: string;
}

export interface IAffiliation {
  campus: string;
  city: string;
  created_at?: string;
  date_began: string;
  date_ended: string;
  email: string;
  id?: number;
  key?: number;
  mobile_number: string;
  name: string;
  relationship: string;
  sorority: string;
  state: string;
  updated_at?: string;
}

export interface IActivity {
  id?: number;
  key?: number;
  subject: string;
  leadership_role: string;
  year_began: string;
  year_ended: string;
}

export interface IAward {
  id?: number;
  key?: number;
  title: string;
  organization: string;
  year: string;
}

export interface ICommunityActivity {
  id?: number;
  key?: number;
  role: string;
  event: string;
  description: string;
}

export interface IJob {
  id?: number;
  key?: number;
  employer: string;
  position: string;
  date_began: string;
  date_ended: string;
}

type IResumeProfile = Pick<
  IProfile,
  | "resume_url"
  | "mobile_number"
  | "street_address"
  | "date_of_birth"
  | "facebook_url"
  | "instagram_name"
  | "snapchat_name"
  | "twitter_name"
  | "profile_photo_url"
  | "name"
  | "preferred_name"
  | "intended_major"
  | "entering_as"
  | "transfer_from"
  | "hs_name"
  | "hs_address"
  | "curriculum"
  | "hs_gpa"
  | "hs_gpa_scale"
  | "hs_class_size"
  | "hs_class_rank"
  | "act_score"
  | "sat_score"
  | "mother_name"
  | "mother_maiden_name"
  | "mother_profession"
  | "mother_college"
  | "mother_degree"
  | "father_name"
  | "father_profession"
  | "father_college"
  | "father_degree"
  | "opt_out_greek_affiliations"
  | "opt_out_activities"
  | "opt_out_awards"
  | "opt_out_community_activities"
  | "opt_out_jobs"
  | "pnm_email"
  | "university"
>;

export interface IResume {
  profile: IResumeProfile | null;
  university?: {
    id: number;
    name: string;
  } | null;
  affiliations: IAffiliation[];
  activities: IActivity[];
  awards: IAward[];
  communityActivities: ICommunityActivity[];
  jobs: IJob[];
}

export interface IFile {
  id: number;
  type: FileTypes;
  url: string;
  filename: string;
}

export enum FileTypes {
  photo = "photo",
  resume = "resume",
  transcript = "transcript",
  profile_photo = "profile_photo",
  generated_resume = "generated_resume",
}

export interface IRecInviteList {
  sororities: ISorority[];
}

export interface ISorority {
  chapter_id: number;
  invites: IRecInvite[];
  sorority_id: number;
  sorority_name: string;
}

export enum WriterStatus {
  UNOPENED = "unopened",
  IN_PROGRESS = "in_progress",
  SENT = "sent",
  BAD_EMAIL = "bad_email",
}

export interface IRecInvite {
  cc_primary: boolean;
  cc_secondary: boolean;
  chapter_id: number;
  created_at: string;
  enabled: boolean;
  id?: number;
  message: string;
  pnm_id?: number;
  reminded_at: string | null;
  resent_at: string | null;
  sent_at: string | null;
  subject: string;
  updated_at: string | null;
  writer_dl_date: string | null;
  writer_email: string;
  writer_name: string;
  writer_status: WriterStatus;
  signature: string | null;
}

export interface IUniversity {
  id: number;
  name: string;
  friendly_name: string;
}

export interface IRecRequestSummary {
  id: number;
  status: string;
  progress: number;
  deadline: string;
  university: {
    id: number;
    name: string;
  };
  pnm: {
    id: number;
    first_name: string | null;
    last_name: string | null;
    profile_image: string | null;
  };
  sorority: {
    id: number;
    name: string;
  };
}

export interface IRecRequest {
  id: number;
  file_download_date: string | null;
  status: string;
  progress: number;
  deadline: string;
  university: {
    id: number;
    name: string;
  };
  pnm: {
    id: number;
    name: string | null;
    preferred_name: string | null;
    street_address: string | null;
    facebook_url: string | null;
    instagram_name: string | null;
    snapchat_name: string | null;
    twitter_name: string | null;
    intended_major: string | null;
    entering_as: string | null;
    transfer_from: string | null;
    hs_name: string | null;
    hs_address: string | null;
    hs_gpa: string | null;
    hs_gpa_scale: string | null;
    hs_class_size: string | number | null;
    hs_class_rank: string | number | null;
    act_score: string | number | null;
    sat_score: string | number | null;
    mother_name: string | null;
    mother_maiden_name: string | null;
    mother_profession: string | null;
    mother_college: string | null;
    mother_degree: string | null;
    father_name: string | null;
    father_profession: string | null;
    father_college: string | null;
    father_degree: string | null;
    user_id: number;
    created_at: string;
    updated_at: string;
    date_of_birth: string | null;
    opt_out_greek_affiliations: boolean;
    opt_out_activities: boolean;
    opt_out_awards: boolean;
    opt_out_community_activities: boolean;
    opt_out_jobs: boolean;
    curriculum: string[] | null;
    complete: boolean | null;
    mobile_number: string | null;
    pnm_email: string | null;
    resume_url: string | null;
    profile_photo_url: string | null;
    activities: IActivity[];
    awards: IAward[];
    community_activities: ICommunityActivity[];
    greek_affiliations: IAffiliation[];
    jobs: IJob[];
  };
  files: IFileItem[];
  sorority: {
    id: number;
    name: string;
  };
}

export interface IFileItem {
  id: number;
  filename: string;
  size: number;
  type: string;
}

export interface INotification {
  id: number;
  description: string;
  created_at: string;
}
