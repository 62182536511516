import React from "react";

import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";

import {
  Row,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { AxiosPromise } from "axios";
import PasswordPeekInput from "./PasswordPeekInput";

export interface IPasswordFormValues {
  password: string;
  password_confirm: string;
}

const formSchema = yup.object().shape({
  password: yup
    .string()
    .min(6)
    .matches(
      /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{6,})/,
      "Password must contain at least one number"
    )
    .label("Password")
    .required(),
  password_confirm: yup
    .string()
    .label("Confirm password")
    .oneOf([yup.ref("password"), null], "Passwords do not match")
    .required(),
});

interface IPrimaryContactFormProps {
  className?: string;
  isOpen: boolean;
  onClose(): void;
  onSave(password: string): AxiosPromise;
}

const PasswordFormModal: React.FC<IPrimaryContactFormProps> = props => {
  function handleSubmit(
    values: IPasswordFormValues,
    actions: FormikActions<IPasswordFormValues>
  ) {
    props.onSave(values.password).then(() => {
      actions.setSubmitting(false);
      props.onClose();
    });
  }

  return (
    <Formik
      initialValues={{
        password: "",
        password_confirm: "",
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<IPasswordFormValues>) => (
        <Modal
          isOpen={props.isOpen}
          toggle={props.onClose}
          className={props.className}
        >
          <Form onSubmit={formikBag.handleSubmit}>
            <ModalHeader toggle={props.onClose}>Password</ModalHeader>
            <ModalBody className="rh-bg-teal">
              <p>Provide your information in the text fields below.</p>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Password</Label>
                    <PasswordPeekInput
                      name="password"
                      type="password"
                      value={formikBag.values.password}
                      onChange={formikBag.handleChange}
                      error={formikBag.errors.password}
                      invalid={
                        !!(
                          formikBag.errors.password &&
                          formikBag.touched.password
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Confirm Password</Label>
                    <PasswordPeekInput
                      name="password_confirm"
                      type="password"
                      value={formikBag.values.password_confirm}
                      onChange={formikBag.handleChange}
                      invalid={
                        !!(
                          formikBag.errors.password_confirm &&
                          formikBag.touched.password_confirm
                        )
                      }
                    />
                    <FormFeedback>
                      {formikBag.errors.password_confirm}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="rh-bg-teal text-right">
              <Button disabled={formikBag.isSubmitting} onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={formikBag.isSubmitting}
              >
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    />
  );
};

export default PasswordFormModal;
