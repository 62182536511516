export const rootPath = "/";

export const signInPath = "sign-in";
export const toSignIn = "/sign-in";
export const signOutPath = "sign-out";
export const toSignOut = "/sign-out";
export const forgotPasswordPath = "forgot-password";
export const toForgotPasswordPath = "/forgot-password";
export const passwordResetPath = "password-reset/:token";

export const emailConfirmationPath = "email-confirmation/:token";

export const createAccountPath = "create-account";
export const toCreateAccount = "/create-account";
export const rwSignUpPath = "sign-up";
export const toRwSignUpPath = "/sign-up";
export const paymentPath = "payment";
export const toPayment = "/payment";

export const dashboardPath = "/";
export const toDashboard = "/";

export const recommendationsPath = "recommendations";
export const toRecommendations = "/recommendations";

export const resumePath = "resume";
export const toResume = "/resume";
export const resumeViewPath = "view";
export const toResumeView = "/resume/view";
export const resumeEditPersonalPath = "edit/personal";
export const toResumeEditPersonal = "/resume/edit/personal";
export const resumeEditEducationPath = "edit/education";
export const toResumeEditEducation = "/resume/edit/education";
export const resumeEditFamilyPath = "edit/family";
export const toResumeEditFamily = "/resume/edit/family";
export const resumeEditGreekAffiliationsPath = "edit/greek-affiliations";
export const toResumeEditGreekAffiliations = "/resume/edit/greek-affiliations";
export const resumeEditActivitiesPath = "edit/activities";
export const toResumeEditActivities = "/resume/edit/activities";
export const resumeEditAwardsPath = "edit/awards";
export const toResumeEditAwards = "/resume/edit/awards";
export const resumeEditCommunityInvolvementPath = "edit/community-involvement";
export const toResumeEditCommunityInvolvement =
  "/resume/edit/community-involvement";
export const resumeEditEmploymentPath = "edit/employment";
export const toResumeEditEmployment = "/resume/edit/employment";
export const resumeEditCompletePath = "edit/complete";
export const toResumeEditComplete = "/resume/edit/complete";

export const filesPath = "files";
export const toFiles = "/files";

export const accountPath = "account";
export const toAccount = "/account";

export const settingsPath = "settings";
export const toSettings = "/settings";

export const recRequestsPath = "/";
export const toRecRequests = "/";

export const privacyPath = "privacy";
export const toPrivacyPath = "/privacy";

export const recDetailPath = "/rec-requests/:id";
export function toRecDetail(id: number) {
  return `/rec-requests/${id}`;
}
