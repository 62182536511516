import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, navigate } from "@reach/router";
import { Card, CardBody, FormGroup, Input, Label, Button } from "reactstrap";

import * as routes from "../../routes";

import { IProfile, IAward } from "../../interfaces";
import AwardForm from "./AwardForm";
import agent from "../../agent";

interface IEditAwardsProps extends RouteComponentProps {
  profile: IProfile;
  formIsDisabled: boolean;
  onUpdateProfile(profile: IProfile): void;
  onSubmit(profile: IProfile): Promise<void>;
}

const emptyAward: IAward = {
  key: new Date().getMilliseconds(),
  title: "",
  organization: "",
  year: "",
};

const EditAwards: React.FC<IEditAwardsProps> = props => {
  const { profile, formIsDisabled } = props;

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }
  }, []);

  const [optOut, setOptOut] = useState(profile.opt_out_awards);
  function toggleOptOut() {
    if (optOut) {
      setOptOut(false);
      addBlankAward();
    } else {
      setOptOut(true);
      setAwards(null);
    }
  }

  function saveAndContinue() {
    agent.Profile.update({
      ...profile,
      opt_out_awards: optOut,
    }).then(res => {
      props.onUpdateProfile(res.data);
      navigate(routes.toResumeEditCommunityInvolvement);
    });
  }

  const [awards, setAwards] = useState<IAward[] | null>(null);

  useEffect(() => {
    fetchAwards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchAwards() {
    agent.Awards.getAll().then(res => {
      if (res.data && res.data.length > 0) {
        setAwards(res.data);
      } else if (res.data && res.data.length === 0 && !profile.opt_out_awards) {
        addBlankAward();
      }
    });
  }

  function addBlankAward() {
    if (awards) {
      setAwards([
        ...awards,
        {
          ...emptyAward,
          key: awards.length,
        },
      ]);
    } else {
      setAwards([emptyAward]);
    }
  }

  function deleteAward(awardToDelete: IAward) {
    if (awardToDelete.id) {
      agent.Awards.delete(awardToDelete.id).then(res => {
        if (awards) {
          setAwards(awards.filter(a => a.id !== awardToDelete.id));
        }
      });
    } else if (awardToDelete.key) {
      if (awards) {
        setAwards(awards.filter(a => a.key !== awardToDelete.key));
      }
    }
  }

  function saveAward(updatedAward: IAward) {
    if (updatedAward.id) {
      return agent.Awards.update(updatedAward.id, updatedAward);
    } else {
      return agent.Awards.create(updatedAward);
    }
  }

  return (
    <>
      <Card className="rh-bg-teal border-0 mb-2">
        <CardBody className="pb-1">
          <p className="mb-2">Please list any awards &amp; honors.</p>
          <FormGroup check className="mr-2">
            <Label check>
              <Input
                type="checkbox"
                name="optOut"
                checked={optOut === true}
                onChange={toggleOptOut}
                disabled={formIsDisabled}
              />{" "}
              I have no Awards &amp; Honors.
            </Label>
          </FormGroup>
        </CardBody>
        {!optOut && awards && (
          <CardBody>
            {awards.map((a, index) => (
              <AwardForm
                key={index}
                award={a}
                onSubmit={saveAward}
                onDelete={deleteAward}
                formIsDisabled={formIsDisabled}
              />
            ))}
            <Button onClick={addBlankAward} disabled={formIsDisabled}>
              <i className="fas fa-plus mr-2" /> Add Another
            </Button>
          </CardBody>
        )}
        <CardBody className="d-flex justify-content-between">
          <Button
            tag={Link}
            to={routes.toResumeEditActivities}
            color="link"
            className="btn-slim"
          >
            <i className="fas fa-chevron-left mr-2" /> Back
          </Button>
          <Button onClick={saveAndContinue} color="primary">
            Continue
          </Button>
        </CardBody>
      </Card>
      <Link to={routes.toResumeEditCommunityInvolvement}>
        Skip and come back to later
      </Link>
    </>
  );
};

export default EditAwards;
