import React, { useState, useEffect, useContext } from "react";
import { Link, RouteComponentProps, navigate } from "@reach/router";
import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";
import {
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Input,
  Label,
  Button,
  CustomInput,
} from "reactstrap";

import UserSettingsContext from "../../context/user-settings-context";
import * as routes from "../../routes";
import { IProfile, IUniversity } from "../../interfaces";

const formSchema = yup
  .object()
  .shape({
    intended_major: yup
      .string()
      .label("Intended Major")
      .required()
      .nullable(),
    entering_as: yup
      .string()
      .label("Level")
      .required()
      .nullable(),
    transfer_from: yup
      .string()
      .label("Transfer University")
      .nullable(),
    hs_name: yup
      .string()
      .label("High School Name")
      .required()
      .nullable(),
    hs_address: yup
      .string()
      .label("Address")
      .nullable(),
    hs_gpa: yup
      .number()
      .label("GPA")
      .required()
      .nullable(),
    hs_gpa_scale: yup
      .number()
      .label("GPA Scale")
      .nullable(),
    hs_class_size: yup
      .number()
      .label("Class Size")
      .nullable(),
    hs_class_rank: yup
      .mixed()
      .test({
        name: "Class Rank",
        message: "Class Rank must be a number or N/A",
        test: val => {
          const schema = yup.number();
          return schema.isValidSync(val) || val === "N/A";
        },
      })

      .label("Class Rank")
      .required()
      .nullable(),
    curriculum: yup
      .array()
      .label("Curriculum")
      .nullable(),
    act_score: yup
      .number()
      .label("ACT Score")
      .nullable(),
    sat_score: yup
      .number()
      .label("SAT Score")
      .nullable(),
  })
  .test(
    "at-least-one-exam-score",
    "You must provide either ACT or SAT score",
    value => !!(value.act_score || value.sat_score)
  );

interface IEditEducationProps extends RouteComponentProps {
  profile: IProfile;
  onSubmit(profile: IProfile): Promise<void>;
  universityList: IUniversity[];
  formIsDisabled: boolean;
}

const EditEducation: React.FC<IEditEducationProps> = props => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }
  }, []);

  const { user } = useContext(UserSettingsContext);
  const { profile, universityList, formIsDisabled } = props;

  function handleSubmit(values: IProfile, actions: FormikActions<IProfile>) {
    const modifiedValues: IProfile = {
      ...values,
      // if it's anything besides a string number, submit null. This supports entering of N/A
      hs_class_rank:
        !isNaN(values.hs_class_rank as any) && values.hs_class_rank !== ""
          ? values.hs_class_rank
          : null,
    };
    props
      .onSubmit({
        ...profile,
        ...modifiedValues,
      })
      .then(() => {
        navigate(routes.toResumeEditFamily);
      });
  }

  const [showTransferUniversity, setShowTransferUniversity] = useState(false);
  function toggleShowTransferUniversity() {
    setShowTransferUniversity(!showTransferUniversity);
  }

  function getClassRankValue(hsClassRank: null | string | number) {
    // If it's not the initial submission profile.intended_major will not be null so if
    // hsClassRank is we know it's N/A
    if (hsClassRank === null && profile.intended_major !== null) {
      return "N/A";
    }

    return hsClassRank || "";
  }

  return (
    <Formik
      initialValues={{
        ...profile,
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<IProfile>) => (
        <Form onSubmit={formikBag.handleSubmit}>
          <Card className="rh-bg-teal border-0 mb-2">
            <CardBody>
              <FormGroup>
                <Label>College/University</Label>
                <Input
                  type="select"
                  name="university_id"
                  disabled
                  value={user.university_id}
                  onChange={formikBag.handleChange}
                >
                  <option />
                  {universityList &&
                    universityList.map(u => (
                      <option key={u.id} value={u.id}>
                        {u.friendly_name}
                      </option>
                    ))}
                </Input>
                <FormText>
                  University cannot be changed without{" "}
                  <a href={`mailto:support@myrechub.com`}>
                    contacting customer support
                  </a>
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label>
                  Intended Major <span className="text-danger">*</span>
                </Label>
                <Input
                  name="intended_major"
                  value={formikBag.values.intended_major || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.intended_major &&
                      formikBag.touched.intended_major
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.intended_major}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>
                  Entering College/University as{" "}
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  type="select"
                  name="entering_as"
                  value={formikBag.values.entering_as || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.entering_as &&
                      formikBag.touched.entering_as
                    )
                  }
                  disabled={formIsDisabled}
                >
                  <option />
                  <option value="Freshman">Freshman</option>
                  <option value="Sophomore">Sophomore</option>
                  <option value="Junior">Junior</option>
                  <option value="Senior">Senior</option>
                  <option value="Other">Other</option>
                </Input>

                <FormFeedback>{formikBag.errors.entering_as}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="transferUniversityCheckbox"
                  label="Are you a transfer student from another campus or university?"
                  checked={
                    showTransferUniversity ||
                    !!(
                      formikBag.values.transfer_from &&
                      formikBag.values.transfer_from.length > 0
                    )
                  }
                  onChange={toggleShowTransferUniversity}
                  disabled={formIsDisabled}
                />
              </FormGroup>
              <FormGroup
                className={
                  showTransferUniversity || formikBag.values.transfer_from
                    ? "d-block"
                    : "d-none"
                }
              >
                <Label>What university did you transfer from?</Label>
                <Input
                  name="transfer_from"
                  value={formikBag.values.transfer_from || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.transfer_from &&
                      formikBag.touched.transfer_from
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.transfer_from}</FormFeedback>
              </FormGroup>
              <h3 className="h5">High School Attended</h3>
              <FormGroup>
                <Label>
                  High School Name <span className="text-danger">*</span>
                </Label>
                <Input
                  name="hs_name"
                  value={formikBag.values.hs_name || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(formikBag.errors.hs_name && formikBag.touched.hs_name)
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.hs_name}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>
                  High School - Full Street Address, City, State, ZIP
                </Label>
                <Input
                  name="hs_address"
                  type="textarea"
                  value={formikBag.values.hs_address || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.hs_address &&
                      formikBag.touched.hs_address
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.hs_address}</FormFeedback>
              </FormGroup>
              <Row>
                <Col xs="6" lg="4">
                  <FormGroup>
                    <Label>
                      GPA <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="hs_gpa"
                      value={formikBag.values.hs_gpa || ""}
                      onChange={formikBag.handleChange}
                      invalid={
                        !!(formikBag.errors.hs_gpa && formikBag.touched.hs_gpa)
                      }
                      disabled={formIsDisabled}
                    />
                    <FormFeedback>{formikBag.errors.hs_gpa}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="6" lg="4">
                  <FormGroup>
                    <Label>GPA Scale</Label>
                    <Input
                      name="hs_gpa_scale"
                      value={formikBag.values.hs_gpa_scale || ""}
                      onChange={formikBag.handleChange}
                      invalid={
                        !!(
                          formikBag.errors.hs_gpa_scale &&
                          formikBag.touched.hs_gpa_scale
                        )
                      }
                      disabled={formIsDisabled}
                    />
                    <FormFeedback>{formikBag.errors.hs_gpa_scale}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="6" lg="4">
                  <FormGroup>
                    <Label>Class Size</Label>
                    <Input
                      name="hs_class_size"
                      value={formikBag.values.hs_class_size || ""}
                      onChange={formikBag.handleChange}
                      invalid={
                        !!(
                          formikBag.errors.hs_class_size &&
                          formikBag.touched.hs_class_size
                        )
                      }
                      disabled={formIsDisabled}
                    />
                    <FormFeedback>
                      {formikBag.errors.hs_class_size}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="6" lg="4">
                  <FormGroup>
                    <Label>
                      Class Rank <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="hs_class_rank"
                      value={getClassRankValue(formikBag.values.hs_class_rank)}
                      onChange={formikBag.handleChange}
                      invalid={
                        !!(
                          formikBag.errors.hs_class_rank &&
                          formikBag.touched.hs_class_rank
                        )
                      }
                      disabled={formIsDisabled}
                    />
                    <FormText>Enter number or N/A if not applicable</FormText>
                    <FormFeedback>
                      {formikBag.errors.hs_class_rank}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="12" lg="8">
                  <FormGroup>
                    <Label>Curriculum</Label>
                    <Input
                      type="select"
                      multiple
                      name="curriculum"
                      value={formikBag.values.curriculum || [""]}
                      onChange={evt =>
                        formikBag.setFieldValue(
                          "curriculum",
                          [].slice
                            //@ts-ignore
                            .call(evt.target.selectedOptions)
                            //@ts-ignore
                            .map(option => option.value)
                        )
                      }
                      invalid={
                        !!(
                          formikBag.errors.curriculum &&
                          formikBag.touched.curriculum
                        )
                      }
                      disabled={formIsDisabled}
                    >
                      <option value="General">General</option>
                      <option value="Honors">Honors</option>
                      <option value="Advanced Placement">
                        Advanced Placement
                      </option>
                      <option value="Dual Enrollment">Dual Enrollment</option>
                      <option value="International Baccalaureate">
                        International Baccalaureate
                      </option>
                      <option value="International Baccalaureate Diploma">
                        International Baccalaureate Diploma
                      </option>
                    </Input>
                    <FormText>
                      Note: Hold down CTRL/CMD to select mutliple options.
                    </FormText>

                    <FormFeedback>{formikBag.errors.curriculum}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <h3 className="h5">Standardized Tests</h3>
              <p>Must provide at least one standardized test score.</p>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>ACT Score</Label>
                    <Input
                      name="act_score"
                      value={formikBag.values.act_score || ""}
                      onChange={formikBag.handleChange}
                      invalid={
                        !!(
                          formikBag.errors.act_score &&
                          formikBag.touched.act_score
                        )
                      }
                      disabled={formIsDisabled}
                    />
                    <FormFeedback>{formikBag.errors.act_score}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>SAT Score</Label>
                    <Input
                      name="sat_score"
                      value={formikBag.values.sat_score || ""}
                      onChange={formikBag.handleChange}
                      invalid={
                        !!(
                          formikBag.errors.sat_score &&
                          formikBag.touched.sat_score
                        )
                      }
                      disabled={formIsDisabled}
                    />
                    <FormFeedback>{formikBag.errors.sat_score}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Alert
                isOpen={
                  Object.keys(formikBag.errors).length > 0 &&
                  (formikBag.touched.sat_score !== undefined ||
                    formikBag.touched.act_score !== undefined) &&
                  !(formikBag.values.act_score || formikBag.values.sat_score)
                }
                color="danger"
              >
                You must provide either ACT or SAT score.
              </Alert>
            </CardBody>
            <CardBody className="d-flex justify-content-between">
              <Button
                tag={Link}
                to={routes.toResumeEditPersonal}
                color="link"
                className="btn-slim"
              >
                <i className="fas fa-chevron-left mr-2" /> Back
              </Button>
              <Button type="submit" color="primary">
                Save and Continue
              </Button>
            </CardBody>
          </Card>
          <Link to={routes.toResumeEditFamily}>
            Skip and come back to later
          </Link>
        </Form>
      )}
    />
  );
};

export default EditEducation;
