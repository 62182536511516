import React, { useState, useEffect, SyntheticEvent } from "react";
import ReactMarkdown from "react-markdown";
import _orderBy from "lodash/orderBy";

import { RouteComponentProps } from "@reach/router";
import {
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Table,
  ListGroup,
} from "reactstrap";

import agent from "../agent";

import {
  ISorority,
  IRecInvite,
  IApiResult,
  IContentfulPage,
  IProfile,
  IDashboardStats,
  WriterStatus,
} from "../interfaces";

import Layout from "../components/Layout";
import InviteTableRow from "../components/InviteTableRow";
import InviteListItem from "../components/InviteListItem";
import Tip from "../components/Tip";

interface IRecInvitesProps extends RouteComponentProps {
  pageContent?: IContentfulPage;
  userUniversityName: string;
}

const RecInvites: React.FC<IRecInvitesProps> = props => {
  const [profile, setProfile] = useState<IProfile | null>(null);
  useEffect(() => {
    agent.Profile.get().then(res => {
      if (res.data) {
        setProfile(res.data);
      }
    });
  }, []);

  const [stats, setStats] = useState<IDashboardStats | null>(null);
  useEffect(() => {
    agent.Dashboard.get().then(res => {
      if (res.data) {
        setStats(res.data);
      }
    });
  }, []);

  const [sororities, setSororities] = useState<ISorority[] | null>(null);
  const { pageContent } = props;

  useEffect(() => {
    fetchSororitiesAndInvites();
  }, []);

  function fetchSororitiesAndInvites() {
    setSororities(null);
    agent.Invitations.getAll().then(res => {
      if (res.data) {
        // give each sorority at least one empty invite
        // so that it shows up in the list
        const list = res.data.sororities.map(sorority => {
          return {
            ...sorority,
            invites: [
              ...sorority.invites,
              {
                cc_primary: false,
                cc_secondary: false,
                chapter_id: sorority.chapter_id,
                created_at: "",
                enabled: false,
                message: "",
                reminded_at: null,
                resent_at: null,
                sent_at: null,
                subject: "",
                updated_at: "",
                writer_dl_date: null,
                writer_email: "",
                writer_name: "",
                writer_status: WriterStatus.UNOPENED,
                signature: null,
              },
            ],
          };
        });
        setSororities(list);
      }
    });
  }

  const [filterText, setFilterText] = useState("");
  function updateFilterText(event: SyntheticEvent<HTMLInputElement>) {
    setFilterText(event.currentTarget.value);
  }

  function applyFilter(sorority: ISorority): boolean {
    if (
      sorority.sorority_name.toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
    ) {
      return true;
    } else {
      return false;
    }
  }

  const [sortOrder, setSortOrder] = useState("asc");
  function handleChangeSortOrder(event: SyntheticEvent<HTMLInputElement>) {
    const newSortOrder = event.currentTarget.value === "asc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
    setSororities(_orderBy(sororities, ["sorority_name"], [newSortOrder]));
  }

  async function sendInvite(i: IRecInvite): Promise<IApiResult> {
    if (i.id) {
      try {
        const sendRes = await agent.Invitations.send(i.id);
        return {
          message: "Invite sent",
          data: sendRes.data,
        };
      } catch (e) {
        return {
          error: true,
          message: "Failed to send invite",
        };
      }
    } else {
      return {
        error: true,
        message: "Failed to send invite",
      };
    }
  }

  function deleteInvite(id: number) {
    agent.Invitations.delete(id)
      .then(res => {
        console.log("PICKLE delete success");
        fetchSororitiesAndInvites();
      })
      .catch(err => {
        console.error("Failed to delete invite", err);
      });
  }

  if (!sororities || !profile || !stats) {
    return null;
  }

  return (
    <Layout>
      <Container className="rh-main-container">
        <h1>Recommendations</h1>
        {pageContent && pageContent.instructions && (
          <ReactMarkdown source={pageContent.instructions} className="mb-1" />
        )}
        <p className="mb-5">Showing 1-3 "Selected" Recommendations</p>

        <Row className="justify-content-between mb-3">
          <Col xs="7" md="4">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="bg-white border-right-0">
                  <i className="fas fa-search" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="search"
                value={filterText}
                onChange={updateFilterText}
                placeholder="Search Sororities"
                className="border-left-0"
              />
            </InputGroup>
          </Col>
          <Col xs="5" md="3" className="d-flex align-items-center">
            <p className="mb-0 mr-2">Sort&nbsp;by</p>
            <Input
              value={sortOrder}
              onChange={handleChangeSortOrder}
              type="select"
              name="sortBy"
            >
              <option value="asc">Sorority (A-Z)</option>
              <option value="desc">Sorority (Z-A)</option>
            </Input>
          </Col>
        </Row>
        <ListGroup className="d-block d-md-none mb-4">
          {sororities &&
            sororities
              .filter(applyFilter)
              .map(sorority =>
                sorority.invites.map(invite => (
                  <InviteListItem
                    key={`${sorority.chapter_id}_${invite.id || "new"}`}
                    invite={invite}
                    inviteInstructions={
                      props.pageContent &&
                      props.pageContent.secondaryInstructions
                    }
                    sorority={sorority}
                    onSend={sendInvite}
                    onDelete={deleteInvite}
                    userUniversityName={props.userUniversityName}
                    profile={profile}
                    stats={stats}
                  />
                ))
              )}
        </ListGroup>
        <Table className="d-none d-md-table mb-4">
          <thead>
            <tr>
              <th>Sorority</th>
              <th>Rec Writer Name</th>
              <th>Rec Writer Email</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {sororities &&
              sororities
                .filter(applyFilter)
                .map(sorority =>
                  sorority.invites.map(invite => (
                    <InviteTableRow
                      key={`${sorority.chapter_id}_${invite.id || "new"}`}
                      invite={invite}
                      inviteInstructions={
                        props.pageContent &&
                        props.pageContent.secondaryInstructions
                      }
                      sorority={sorority}
                      onSend={sendInvite}
                      onDelete={deleteInvite}
                      userUniversityName={props.userUniversityName}
                      profile={profile}
                      stats={stats}
                    />
                  ))
                )}
          </tbody>
        </Table>
        <Row>
          {pageContent &&
            pageContent.tips &&
            pageContent.tips.map((tip, index) => (
              <Col key={index} md="4" className="mb-3 mb-md-0">
                <Tip text={tip} />
              </Col>
            ))}
        </Row>
      </Container>
    </Layout>
  );
};

export default RecInvites;
