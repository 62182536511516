import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import ProfilePic from "./ProfilePic";
import { getStatus } from "../pages/RecRequests";
import profilePlaceholder from "../images/profile_placeholder_1.png";

interface IProps {
  progress: number;
  photoUrl: string;
  status: string;
  name: string;
  university: string;
  mobile: string;
  email: string;
  sorority: string;
}

const RecRequestDetailedCard = ({
  progress,
  photoUrl,
  status,
  name,
  university,
  mobile,
  email,
  sorority,
}: IProps) => {
  const profilePicUrl = photoUrl || profilePlaceholder;
  return (
    <Card className="border-0">
      <CardBody className="px-0 pt-0 pb-3">
        <Row className="align-items-center">
          <Col xs="3">
            <ProfilePic percentage={progress} photoUrl={profilePicUrl} small />
          </Col>
          <Col xs="8">
            <div className="text-muted">
              <strong className="d-block d-md-none">{name}</strong>
              <h2 className="text-dark d-none d-md-block">{name}</h2>
              <small
                className="text-uppercase text-info"
                style={{ letterSpacing: "0.1em" }}
              >
                {university}
              </small>
              <small
                className="text-uppercase text-info d-block"
                style={{ letterSpacing: "0.1em" }}
              >
                {sorority}
              </small>
              <br />
              <div className="d-flex">
                <div className="d-flex flex-column pr-2">
                  <strong>Email</strong>
                  <strong>Mobile</strong>
                </div>
                <div className="d-flex flex-column">
                  <span>{email}</span>
                  <span>{mobile}</span>
                </div>
              </div>
              <small>
                Rec Status: {getStatus(status)} (
                <span className="text-primary">{progress}%</span>)
              </small>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default RecRequestDetailedCard;
