import React from "react";
import { navigate } from "@reach/router";
import { Card, CardBody, Row, Col, Badge } from "reactstrap";
import ProfilePic from "./ProfilePic";
import { toRecDetail } from "../routes";
import profilePlaceholder from "../images/profile_placeholder_1.png";

interface IProps {
  id: number;
  progress: number;
  photoUrl: string;
  status: string;
  name: string;
  university: string;
  deadline: string;
  sorority: string;
}

const RecRequestCard = ({
  id,
  progress,
  photoUrl,
  status,
  name,
  university,
  deadline,
  sorority,
}: IProps) => {
  const profilePicUrl = photoUrl || profilePlaceholder;
  return (
    <>
      {/* Non-Mobile Card */}
      <Card
        style={{ cursor: "pointer" }}
        className="border-0 d-none d-md-block"
        onClick={() => navigate(toRecDetail(id))}
      >
        <CardBody className="rh-bg-teal text-center">
          <ProfilePic percentage={progress} photoUrl={profilePicUrl} />
          <p>
            Rec Status: {status} (
            <span className="text-primary">{progress}%</span>)
          </p>
          <div
            className="rh-bg-teal-dark 100-w text-muted py-2"
            style={{
              // comes from padding for card itself. trying to get this section to be flush with sides of card
              marginLeft: "-1.25rem",
              marginRight: "-1.25rem",
            }}
          >
            <strong>{name}</strong>
            <br />
            <span className="text-uppercase" style={{ letterSpacing: "0.1em" }}>
              {university}
            </span>
            <span
              className="text-uppercase d-block"
              style={{ letterSpacing: "0.1em" }}
            >
              {sorority}
            </span>
          </div>
          <p
            style={{
              borderLeftWidth: ".5rem",
              borderLeftStyle: "solid",
            }}
            className="text-left border-primary pl-2 mb-0 mt-3"
          >
            <strong>Deadline: </strong>
            {deadline}
          </p>
        </CardBody>
      </Card>
      {/* Mobile Card */}
      <Card
        className="border-left-0 border-right-0 border-top-0 border-bottom d-block d-md-none"
        onClick={() => navigate(toRecDetail(id))}
      >
        <CardBody className="px-0 pt-0 pb-3">
          <Row className="align-items-center">
            <Col xs="3">
              <ProfilePic percentage={progress} photoUrl={profilePicUrl} />
            </Col>
            <Col xs="7">
              <div className="text-muted">
                <strong>{name}</strong>
                <br />
                <small
                  className="text-uppercase text-info"
                  style={{ letterSpacing: "0.1em" }}
                >
                  {university}
                </small>
              </div>
              <small
                style={{
                  borderLeftWidth: ".5rem",
                  borderLeftStyle: "solid",
                }}
                className="text-left border-primary pl-2 mt-3"
              >
                <strong className="text-info">Deadline: </strong>
                {deadline}
              </small>
            </Col>
            <Col xs="2">
              <Badge
                color="primary"
                className="rh-badge-circle text-white mr-2"
              >
                ?
              </Badge>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default RecRequestCard;
