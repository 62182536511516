import React from "react";
import { Row, Col } from "reactstrap";
import Tip from "./Tip";
import { IContentfulPage } from "../interfaces";

interface IProps {
  pageContent?: IContentfulPage;
}

export default function ContentfulTips({ pageContent }: IProps) {
  return (
    <>
      <hr className="mb-5" />
      <Row>
        {pageContent &&
          pageContent.tips &&
          pageContent.tips.map((tip, index) => (
            <Col key={index} md="4" className="mb-3 mb-md-0">
              <Tip text={tip} />
            </Col>
          ))}
      </Row>
    </>
  );
}
