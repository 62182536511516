import React, { useContext } from "react";

import UserSettingsContext from "../context/user-settings-context";

import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout: React.FC = props => {
  const { user } = useContext(UserSettingsContext);

  return (
    <>
      <Navbar user={user} />
      <div className="rh-main-content mb-7 flex-grow-1">{props.children}</div>
      <Footer />
    </>
  );
};

export default Layout;
