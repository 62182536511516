import { IContentfulPage } from "../interfaces";

export const CONTENTFUL_SPACE_ID = "9c50cr23m5e3";
export const CONTENTFUL_ACCESS_TOKEN =
  "a70b080e6cecd0b3180afa801da8d012fee88860b1d0d5af8178ac9b28366875";

export const parseContentfulPages = (entries: {
  items: any[];
}): IContentfulPage[] => {
  const pages = entries.items
    .map(i => i.fields)
    .map(page => {
      //@ts-ignore
      if (page.tips) {
        return {
          ...page,
          //@ts-ignore
          tips: page.tips.map(t => t.fields.text),
        };
      } else {
        return page;
      }
    });
  return pages;
};
