import React from "react";
import * as yup from "yup";
import { Formik, FormikProps, FormikActions } from "formik";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Button,
} from "reactstrap";
import InputMask from "react-input-mask";

import { IAffiliation } from "../../interfaces";
import { stateOptions, pastYearOptions } from "../../utils/formHelpers";
import { AxiosPromise } from "axios";

const formSchema = yup.object().shape({
  campus: yup
    .string()
    .label("Campus")
    .required(),
  city: yup
    .string()
    .label("City")
    .required(),
  date_began: yup
    .date()
    .label("Start year")
    .nullable(),
  date_ended: yup
    .date()
    .label("End year")
    .min(yup.ref("date_began"), "End year must be after start year")
    .nullable(),
  email: yup
    .string()
    .label("Email")
    .required(),
  mobile_number: yup
    .string()
    .label("Phone")
    .required(),
  name: yup
    .string()
    .label("Full Name")
    .required(),
  relationship: yup
    .string()
    .label("Relationship")
    .required(),
  sorority: yup
    .string()
    .label("Sorority")
    .required(),
  state: yup
    .string()
    .label("State")
    .required(),
});

interface IGreekAffiliationFormProps {
  affiliation: IAffiliation;
  formIsDisabled: boolean;
  onSubmit(affiliation: IAffiliation): AxiosPromise<IAffiliation>;
  onDelete(affiliation: IAffiliation): void;
}

const GreekAffiliationForm: React.FC<IGreekAffiliationFormProps> = props => {
  const { affiliation, formIsDisabled } = props;

  function handleSubmit(
    values: IAffiliation,
    actions: FormikActions<IAffiliation>
  ) {
    props
      .onSubmit({
        ...values,
      })
      .then(() => {
        actions.setSubmitting(false);
        actions.resetForm(values);
      });
  }

  return (
    <Formik
      initialValues={{
        ...affiliation,
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<IAffiliation>) => (
        <Form onSubmit={formikBag.handleSubmit}>
          <FormGroup>
            <Label>
              Full Name <span className="text-danger">*</span>
            </Label>
            <Input
              name="name"
              value={formikBag.values.name || ""}
              onChange={formikBag.handleChange}
              invalid={!!(formikBag.errors.name && formikBag.touched.name)}
              disabled={formIsDisabled}
            />
            <FormFeedback>{formikBag.errors.name}</FormFeedback>
          </FormGroup>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label>
                  Relationship <span className="text-danger">*</span>
                </Label>
                <Input
                  name="relationship"
                  value={formikBag.values.relationship || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.relationship &&
                      formikBag.touched.relationship
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.relationship}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label>
                  Sorority <span className="text-danger">*</span>
                </Label>
                <Input
                  name="sorority"
                  value={formikBag.values.sorority || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(formikBag.errors.sorority && formikBag.touched.sorority)
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.sorority}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label>
              College or University Campus{" "}
              <span className="text-danger">*</span>
            </Label>
            <Input
              name="campus"
              value={formikBag.values.campus || ""}
              onChange={formikBag.handleChange}
              invalid={!!(formikBag.errors.campus && formikBag.touched.campus)}
              disabled={formIsDisabled}
            />
            <FormFeedback>{formikBag.errors.campus}</FormFeedback>
          </FormGroup>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label>
                  City <span className="text-danger">*</span>
                </Label>
                <Input
                  name="city"
                  value={formikBag.values.city || ""}
                  onChange={formikBag.handleChange}
                  invalid={!!(formikBag.errors.city && formikBag.touched.city)}
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.city}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label>
                  State <span className="text-danger">*</span>
                </Label>
                <Input
                  name="state"
                  type="select"
                  value={formikBag.values.state || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(formikBag.errors.state && formikBag.touched.state)
                  }
                  disabled={formIsDisabled}
                >
                  <option>Choose</option>
                  {stateOptions.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.label}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{formikBag.errors.state}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <p>Dates Attended</p>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label>Start Year</Label>
                <Input
                  name="date_began"
                  type="select"
                  value={formikBag.values.date_began || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.date_began &&
                      formikBag.touched.date_began
                    )
                  }
                  disabled={formIsDisabled}
                >
                  <option value="" />
                  {pastYearOptions(100).map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{formikBag.errors.date_began}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label>End Year</Label>
                <Input
                  name="date_ended"
                  type="select"
                  value={formikBag.values.date_ended || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.date_ended &&
                      formikBag.touched.date_ended
                    )
                  }
                  disabled={formIsDisabled}
                >
                  <option value="" />
                  {pastYearOptions(100).map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{formikBag.errors.date_ended}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label>
              Contact's Email Address <span className="text-danger">*</span>
            </Label>
            <Input
              name="email"
              value={formikBag.values.email || ""}
              onChange={formikBag.handleChange}
              invalid={!!(formikBag.errors.email && formikBag.touched.email)}
              disabled={formIsDisabled}
            />
            <FormFeedback>{formikBag.errors.email}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>
              Contact's Phone Number <span className="text-danger">*</span>
            </Label>
            <Input
              name="mobile_number"
              value={formikBag.values.mobile_number || ""}
              onChange={formikBag.handleChange}
              type="tel"
              maskChar=" "
              mask={"(999) 999-9999"}
              tag={InputMask}
              invalid={
                !!(
                  formikBag.errors.mobile_number &&
                  formikBag.touched.mobile_number
                )
              }
              disabled={formIsDisabled}
            />
            <FormFeedback>{formikBag.errors.mobile_number}</FormFeedback>
          </FormGroup>
          <div className="d-flex justify-content-between">
            <Button
              className="mr-2"
              onClick={() => props.onDelete(affiliation)}
              disabled={formIsDisabled}
            >
              Delete
            </Button>
            <Button
              color="primary"
              type="submit"
              className={formikBag.dirty ? "d-block" : "d-none"}
              disabled={formIsDisabled}
            >
              Save
            </Button>
          </div>
          <hr />
        </Form>
      )}
    />
  );
};

export default GreekAffiliationForm;
