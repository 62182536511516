import React from "react";

import { IUser, IAccountSettings } from "../interfaces";
import { EMPTY_USER } from "../constants";

export interface IUserSettings {
  user: IUser;
  updateUser(user: IUser): void;
  settings: IAccountSettings;
  updateSettings(settings: IAccountSettings): void;
  userUniversityName: string;
}

const initialContext: IUserSettings = {
  user: EMPTY_USER,
  updateUser: (user: IUser) => {},
  settings: {
    amount_paid: "",
    display_name: "",
    email: "",
    email_frequency: "",
    first_name: "",
    full_name: "",
    last_name: "",
    mobile_number: "",
    notify_primary: false,
    notify_secondary: false,
    secondary_display_name: "",
    secondary_email: "",
    secondary_full_name: "",
    secondary_mobile_number: "",
    send_notification_emails: false,
    user_type: "",
  },
  updateSettings: (settings: IAccountSettings) => {},
  userUniversityName: "",
};

export default React.createContext(initialContext);
