import React, { useContext, ReactType } from "react";
import Payment from "../pages/Payment";
import { RouteComponentProps } from "@reach/router";
import userSettingsContext from "../context/user-settings-context";

interface Props extends RouteComponentProps {
  as: ReactType;
  [rest: string]: any;
}

const PaidRoute: React.FC<Props> = ({ as: Component, ...rest }) => {
  const { user } = useContext(userSettingsContext);

  return user.paid ? <Component {...rest} /> : <Payment />;
};

export default PaidRoute;
