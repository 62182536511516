import React from "react";
import { Table } from "reactstrap";
import { IFileItem } from "../interfaces";
import FileDownload from "./FileDownload";
import { humanize, formatBytes } from "../utils/genericUtils";

interface Props {
  files: IFileItem[];
  recId: number;
  updateRecRequest(): void;
}

function getFileIcon(type: string) {
  if (type === "png" || type === "jpg" || type === "jpeg") {
    return "image";
  }
  return "file-alt";
}

const FileDetails = (props: Props) => {
  return (
    <>
      <FileDownload
        recId={props.recId}
        updateRecRequest={props.updateRecRequest}
      />
      <Table className="pt-2">
        <thead>
          <tr>
            <th>File Type</th>
            <th>File Name</th>
          </tr>
        </thead>
        <tbody>
          {props.files.map(f => {
            const fileType = f.filename.split(".").pop() || "";

            return (
              <tr key={f.id}>
                <td className="d-flex align-items-center">
                  <i
                    className={`fas fa-2x fa-${getFileIcon(
                      fileType
                    )} pr-3 text-info`}
                  />
                  {humanize(f.type)}
                </td>
                <td>
                  <p className="mb-0">{f.filename}</p>
                  <p className="mb-0">{formatBytes(f.size)}</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default FileDetails;
