import React from "react";

import { Row, Col } from "reactstrap";

interface IStepItem {
  smallText: string;
  bigText: string;
}

interface IWizardStepsProps {
  className?: string;
  currentStepIndex: number;
  steps: IStepItem[];
}

const numberCircleStyle = {
  height: "34px",
  width: "34px",
};

const WizardSteps: React.FC<IWizardStepsProps> = props => {
  return (
    <Row className={`justify-content-center ${props.className}`}>
      {props.steps.map((step, index) => (
        <Col key={index} xs="auto" className="d-flex align-items-center">
          {props.currentStepIndex > index ? (
            <div
              className="rounded-circle text-center mr-2 bg-info text-white"
              style={numberCircleStyle}
            >
              <span className="d-inline-block" style={{ paddingTop: "5px" }}>
                <i className="fas fa-check" />
              </span>
            </div>
          ) : (
            <div
              className={`rounded-circle text-center mr-2 ${
                props.currentStepIndex === index
                  ? "bg-primary text-white"
                  : "border"
              }`}
              style={numberCircleStyle}
            >
              <span className="d-inline-block pt-1">{index + 1}</span>
            </div>
          )}
          <div>
            <p className="text-light" style={{ marginBottom: "-5px" }}>
              <small>{step.smallText}</small>
            </p>
            <p className="h5 mb-0">{step.bigText}</p>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default WizardSteps;
