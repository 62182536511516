import React, { useEffect, useState } from "react";
import { Formik, FormikProps, FormikActions } from "formik";
import { RouteComponentProps } from "@reach/router";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Form,
  FormGroup,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { IAccountSettings } from "../interfaces";
import agent from "../agent";

import Layout from "../components/Layout";

const Settings: React.FC<RouteComponentProps> = props => {
  const [accountData, setAccountData] = useState<IAccountSettings | null>(null);
  useEffect(() => {
    fetchAccountData();
  }, []);

  function fetchAccountData() {
    agent.User.getSettings().then(res => {
      if (res.data) {
        setAccountData(res.data);
      }
    });
  }

  function handleSubmit(
    values: IAccountSettings,
    actions: FormikActions<IAccountSettings>
  ) {
    agent.User.updateSettings({
      ...accountData,
      ...values,
    }).then(res => {
      setAccountData(res.data);
      actions.setSubmitting(false);
      actions.resetForm(values);
    });
  }

  const [deleteAccountModalIsOpen, setDeleteAccountModalIsOpen] = useState(
    false
  );

  function deleteAccount() {
    console.log("PICKLE TODO delete account");
    // then navigate(routes.toSignIn)
  }

  return (
    <Layout>
      <Container className="rh-main-container">
        <h1 className="mb-5">Settings</h1>
        <h2 className="h3 mb-3">Contacts &amp; Notifications</h2>
        {accountData && (
          <Formik
            initialValues={{
              ...accountData,
            }}
            onSubmit={handleSubmit}
            render={(formikBag: FormikProps<IAccountSettings>) => (
              <Form onSubmit={formikBag.handleSubmit}>
                <Row className="mb-3">
                  <Col xs="6" md="3" lg="auto">
                    <FormGroup>
                      <p className="font-weight-bold">Primary Contact</p>
                      <CustomInput
                        type="checkbox"
                        name="notify_primary"
                        id="notify_primary"
                        label="Account holder"
                        checked={formikBag.values.notify_primary}
                        onChange={formikBag.handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6" md="3" lg="auto">
                    <FormGroup>
                      <p className="font-weight-bold">Secondary Contact</p>
                      <CustomInput
                        type="checkbox"
                        name="notify_secondary"
                        id="notify_secondary"
                        label="Parent or guardian"
                        checked={formikBag.values.notify_secondary}
                        onChange={formikBag.handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="3" lg="auto" className="flex-grow-1">
                    <p className="font-weight-bold">Notifications</p>
                    <div className="d-flex">
                      <FormGroup className="mr-3">
                        <CustomInput
                          type="checkbox"
                          name="send_notification_emails"
                          id="send_notification_emails"
                          label="Email"
                          checked={formikBag.values.send_notification_emails}
                          onChange={formikBag.handleChange}
                        />
                      </FormGroup>
                      <FormGroup className="d-flex">
                        <CustomInput
                          type="radio"
                          name="email_frequency"
                          id="daily"
                          label="Daily"
                          value="daily"
                          checked={formikBag.values.email_frequency === "daily"}
                          onChange={formikBag.handleChange}
                          className="mr-3"
                        />
                        <CustomInput
                          type="radio"
                          name="email_frequency"
                          id="immediate"
                          label="Immediate"
                          value="immediate"
                          checked={
                            formikBag.values.email_frequency === "immediate"
                          }
                          onChange={formikBag.handleChange}
                        />
                      </FormGroup>
                    </div>
                  </Col>
                  <Col sm="6" md="12" lg="auto">
                    <br />
                    <Button
                      disabled={!formikBag.dirty}
                      type="submit"
                      color="primary"
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          />
        )}
        <h2 className="h3 mb-3">Delete Account</h2>
        <Alert color="danger">
          <Row className="align-items-center">
            <Col>
              <p>
                <strong>
                  I would like to permanently delete this account.
                </strong>
                <br />
                The account will no longer be available and all data will be
                permanently deleted.
                <br />
                <small>Privacy Policy</small>
              </p>
            </Col>
            <Col sm="auto">
              <Button
                color="danger"
                onClick={() => setDeleteAccountModalIsOpen(true)}
              >
                Continue
              </Button>
            </Col>
          </Row>
        </Alert>
      </Container>
      <Modal
        isOpen={deleteAccountModalIsOpen}
        toggle={() => setDeleteAccountModalIsOpen(false)}
      >
        <ModalHeader>Delete Account</ModalHeader>
        <ModalBody>
          <p>Thank you for using RecHub!</p>
          <p>Delete account form coming soon...</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setDeleteAccountModalIsOpen(false)}>
            Cancel
          </Button>
          <Button color="danger" onClick={deleteAccount}>
            Permanently Delete My Account
          </Button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
};

export default Settings;
