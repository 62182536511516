import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Layout from "../components/Layout";
import { Container } from "reactstrap";
import iconLogoC from "../images/icon-logo-c.svg";
import agent from "../agent";

interface IEmailConfirmationProps
  extends RouteComponentProps<{ token: string }> {
  onConfirmation(): void;
}

const EmailConfirmation: React.FC<IEmailConfirmationProps> = props => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  useEffect(() => {
    if (!props.token) {
      setErrorMessage(
        "No email token found to verify. Please directly follow the link from your email and try again."
      );
      return;
    }
    agent.Auth.confirmEmail(props.token)
      .then(() => {
        setSuccessMessage("Email verified! Taking you to your dashboard!");
        // delaying navigation to give user time to read message
        setTimeout(() => {
          props.onConfirmation();
        }, 1000);
      })
      .catch(() => {
        setErrorMessage(
          "Sorry, we had an issue validating this email. Please click the link found in your email again."
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <Container className="pt-3">
        <div className="text-center">
          <img src={iconLogoC} alt="Rec Hub logo icon" className="mb-3" />
          <h1 className="text-center mb-3">
            {!errorMessage && !successMessage && "Confirming Email..."}
            {errorMessage != null && errorMessage}
            {successMessage != null && successMessage}
          </h1>
        </div>
      </Container>
    </Layout>
  );
};

export default EmailConfirmation;
