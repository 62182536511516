import React from "react";
import CircularProgress from "./CircularProgress";

interface Props {
  photoUrl: string;
  percentage: number;
  small?: boolean;
  alerts?: number;
}

const ProfilePic = ({ percentage, photoUrl, small, alerts }: Props) => {
  return (
    <>
      <div
        className={`rh-circular-progress ${
          small ? "progress-sm" : ""
        } mx-auto mb-3 d-none d-md-block`}
      >
        <CircularProgress
          percentage={percentage}
          strokeWidth={2}
          initialAnimation={true}
          alerts={alerts}
        >
          <img
            alt="profile"
            className="rounded-circle"
            width={small ? "90%" : "95%"}
            height={small ? "90%" : "95%"}
            style={{ objectFit: "cover" }}
            src={photoUrl}
          />
        </CircularProgress>
      </div>
      <div className="rh-circular-progress rh-circular-progress-sm d-block d-md-none">
        <CircularProgress
          percentage={percentage}
          strokeWidth={5}
          initialAnimation={true}
        >
          <img
            className="rounded-circle"
            width="80%"
            height="80%"
            src={photoUrl}
            alt="profile"
          />
        </CircularProgress>
      </div>
    </>
  );
};

export default ProfilePic;
