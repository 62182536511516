import React, { useState, useEffect } from "react";
import { Link, RouteComponentProps, navigate } from "@reach/router";
import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Button,
  CustomInput,
} from "reactstrap";

import * as routes from "../../routes";
import { IProfile } from "../../interfaces";

const formSchema = yup.object().shape({
  mother_name: yup
    .string()
    .label("Full Name")
    .required()
    .nullable(),
  mother_maiden_name: yup
    .string()
    .label("Maiden Name")
    .required()
    .nullable(),
  mother_profession: yup
    .string()
    .label("Profession")
    .required()
    .nullable(),
  mother_college: yup
    .string()
    .label("College")
    .nullable(),
  mother_degree: yup
    .string()
    .label("Degree Title")
    .nullable(),
  father_name: yup
    .string()
    .label("Full Name")
    .required()
    .nullable(),
  father_profession: yup
    .string()
    .label("Profession")
    .required()
    .nullable(),
  father_college: yup
    .string()
    .label("College")
    .nullable(),
  father_degree: yup
    .string()
    .label("Degree Title")
    .nullable(),
});

interface IEditFamilyProps extends RouteComponentProps {
  profile: IProfile;
  onSubmit(profile: IProfile): Promise<void>;
  formIsDisabled: boolean;
}

const EditFamily: React.FC<IEditFamilyProps> = props => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }
  }, []);

  const { profile, formIsDisabled } = props;

  const [showMotherCollege, setShowMotherCollege] = useState(
    !!profile.mother_college
  );
  const [showMotherDegree, setShowMotherDegree] = useState(
    !!profile.mother_degree
  );
  const [showFatherCollege, setShowFatherCollege] = useState(
    !!profile.father_college
  );
  const [showFatherDegree, setShowFatherDegree] = useState(
    !!profile.father_degree
  );
  function handleSubmit(values: IProfile, actions: FormikActions<IProfile>) {
    props
      .onSubmit({
        ...profile,
        ...values,
      })
      .then(() => {
        navigate(routes.toResumeEditGreekAffiliations);
      });
  }

  return (
    <Formik
      initialValues={{
        ...profile,
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<IProfile>) => (
        <Form onSubmit={formikBag.handleSubmit}>
          <Card className="rh-bg-teal border-0 mb-2">
            <CardBody>
              <h3>Mother</h3>
              <FormGroup>
                <Label>
                  Full Name <span className="text-danger">*</span>
                </Label>
                <Input
                  name="mother_name"
                  value={formikBag.values.mother_name || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.mother_name &&
                      formikBag.touched.mother_name
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.mother_name}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>
                  Maiden Name <span className="text-danger">*</span>
                </Label>
                <Input
                  name="mother_maiden_name"
                  value={formikBag.values.mother_maiden_name || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.mother_maiden_name &&
                      formikBag.touched.mother_maiden_name
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>
                  {formikBag.errors.mother_maiden_name}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>
                  Profession <span className="text-danger">*</span>
                </Label>
                <Input
                  name="mother_profession"
                  value={formikBag.values.mother_profession || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.mother_profession &&
                      formikBag.touched.mother_profession
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>
                  {formikBag.errors.mother_profession}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  name="motherDidAttendUniversity"
                  id="motherDidAttendUniversityCheckbox"
                  label="Attended a College or University"
                  checked={showMotherCollege}
                  onChange={() => {
                    formikBag.setFieldValue("mother_college", "");
                    formikBag.setFieldValue("mother_degree", "");
                    if (showMotherCollege) {
                      // we are toggling OFF here
                      setShowMotherDegree(false);
                    }
                    setShowMotherCollege(!showMotherCollege);
                  }}
                  disabled={formIsDisabled}
                />
              </FormGroup>
              {showMotherCollege && (
                <>
                  <FormGroup>
                    <Label>College/University</Label>
                    <Input
                      name="mother_college"
                      value={formikBag.values.mother_college || ""}
                      onChange={formikBag.handleChange}
                      invalid={
                        !!(
                          formikBag.errors.mother_college &&
                          formikBag.touched.mother_college
                        )
                      }
                      disabled={formIsDisabled}
                    />
                    <FormFeedback>
                      {formikBag.errors.mother_college}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <CustomInput
                      type="checkbox"
                      name="showMotherDegree"
                      id="showMotherDegreeCheckbox"
                      label="Obtained a Degree"
                      checked={
                        showMotherDegree || !!formikBag.values.mother_degree
                      }
                      onChange={() => {
                        formikBag.setFieldValue("mother_degree", "");
                        setShowMotherDegree(!showMotherDegree);
                      }}
                      disabled={formIsDisabled}
                    />
                  </FormGroup>
                </>
              )}
              {showMotherDegree && (
                <FormGroup>
                  <Label>Degree Title</Label>
                  <Input
                    name="mother_degree"
                    value={formikBag.values.mother_degree || ""}
                    onChange={formikBag.handleChange}
                    invalid={
                      !!(
                        formikBag.errors.mother_degree &&
                        !!formikBag.touched.mother_degree
                      )
                    }
                    disabled={formIsDisabled}
                  />
                  <FormFeedback>{formikBag.errors.mother_degree}</FormFeedback>
                </FormGroup>
              )}
              <h3>Father</h3>
              <FormGroup>
                <Label>
                  Full Name <span className="text-danger">*</span>
                </Label>
                <Input
                  name="father_name"
                  value={formikBag.values.father_name || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.father_name &&
                      formikBag.touched.father_name
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>{formikBag.errors.father_name}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>
                  Profession <span className="text-danger">*</span>
                </Label>
                <Input
                  name="father_profession"
                  value={formikBag.values.father_profession || ""}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.father_profession &&
                      formikBag.touched.father_profession
                    )
                  }
                  disabled={formIsDisabled}
                />
                <FormFeedback>
                  {formikBag.errors.father_profession}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  name="fatherDidAttendUniversity"
                  id="fatherDidAttendUniversityCheckbox"
                  label="Attended a College or University"
                  checked={
                    showFatherCollege || !!formikBag.values.father_college
                  }
                  onChange={() => {
                    formikBag.setFieldValue("father_college", "");
                    formikBag.setFieldValue("father_degree", "");
                    if (showFatherCollege) {
                      // we are toggling OFF here
                      setShowFatherDegree(false);
                    }
                    setShowFatherCollege(!showFatherCollege);
                  }}
                  disabled={formIsDisabled}
                />
              </FormGroup>
              {showFatherCollege && (
                <>
                  <FormGroup>
                    <Label>College/University</Label>
                    <Input
                      name="father_college"
                      value={formikBag.values.father_college || ""}
                      onChange={formikBag.handleChange}
                      invalid={
                        !!(
                          formikBag.errors.father_college &&
                          formikBag.touched.father_college
                        )
                      }
                      disabled={formIsDisabled}
                    />
                    <FormFeedback>
                      {formikBag.errors.father_college}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <CustomInput
                      type="checkbox"
                      name="showFatherDegree"
                      id="showFatherDegreeCheckbox"
                      label="Obtained a Degree"
                      checked={
                        showFatherDegree || !!formikBag.values.father_degree
                      }
                      onChange={() => {
                        formikBag.setFieldValue("father_degree", "");
                        setShowFatherDegree(!showFatherDegree);
                      }}
                      disabled={formIsDisabled}
                    />
                  </FormGroup>
                </>
              )}
              {showFatherDegree && (
                <FormGroup>
                  <Label>Degree Title</Label>
                  <Input
                    name="father_degree"
                    value={formikBag.values.father_degree || ""}
                    onChange={formikBag.handleChange}
                    invalid={
                      !!(
                        formikBag.errors.father_degree &&
                        formikBag.touched.father_degree
                      )
                    }
                    disabled={formIsDisabled}
                  />
                  <FormFeedback>{formikBag.errors.father_degree}</FormFeedback>
                </FormGroup>
              )}
            </CardBody>
            <CardBody className="d-flex justify-content-between">
              <Button
                tag={Link}
                to={routes.toResumeEditEducation}
                color="link"
                className="btn-slim"
              >
                <i className="fas fa-chevron-left mr-2" /> Back
              </Button>
              <Button type="submit" color="primary">
                Save and Continue
              </Button>
            </CardBody>
          </Card>
          <Link to={routes.toResumeEditGreekAffiliations}>
            Skip and come back to later
          </Link>
        </Form>
      )}
    />
  );
};

export default EditFamily;
