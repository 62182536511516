import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Container } from "reactstrap";

import agent from "../agent";
import { IUniversity, IApiResult } from "../interfaces";
import { signUpSteps } from "../constants";

import Layout from "../components/Layout";
import WizardSteps from "../components/WizardSteps";
import CreateAccountForm, {
  ICreateAccountFormValues,
} from "../components/CreateAccountForm";

import iconLogoC from "../images/icon-logo-c.svg";
import { toRwSignUpPath } from "../routes";

interface ICreateAccountProps extends RouteComponentProps {
  onCreateAccount(email: string, password: string): void;
  universityList: IUniversity[];
}

function capitalize(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

const CreateAccount: React.FC<ICreateAccountProps> = props => {
  function createUserAccount(
    formValues: ICreateAccountFormValues
  ): Promise<IApiResult> {
    return agent.User.createAccount(formValues)
      .then(res => {
        console.log("user account created:", res);
        props.onCreateAccount(formValues.email, formValues.password);
        return {
          message: "Account created",
          data: res.data,
        };
      })
      .catch(err => {
        console.log("CreateAccount err:", err.response);
        let errorMessage = "There was a problem creating your account.";
        const { errors } = err.response.data;
        // process errors for display
        // errors is an array
        // each error item has a detail object that look like this:
        // { email: "is already taken" }
        // for now we'll just display the first error
        if (errors && errors[0].detail) {
          errorMessage = Object.keys(formValues).reduce((acc, curr) => {
            if (errors[0].detail[curr]) {
              const msg = `${capitalize(curr)} ${errors[0].detail[curr]}`;
              return `${acc}${msg}`;
            } else {
              return acc;
            }
          }, "");
        }

        return {
          error: true,
          message: errorMessage,
        };
      });
  }

  return (
    <Layout>
      <Container className="pt-3">
        <div className="text-center">
          <img src={iconLogoC} alt="Rec Hub logo icon" className="mb-3" />
          <h1 className="text-center mb-3">
            Sign Up for Potential New Sorority Members
          </h1>
          <p>
            Did you get invited to write a rec?{" "}
            <a href={toRwSignUpPath}>Click here</a> to sign up as a rec writer!
          </p>
        </div>
        <WizardSteps
          className="mb-4"
          currentStepIndex={0}
          steps={signUpSteps}
        />
        {props.universityList && (
          <CreateAccountForm
            onSubmit={createUserAccount}
            universityList={props.universityList}
          />
        )}
      </Container>
    </Layout>
  );
};

export default CreateAccount;
