import React, { useState, useEffect, useContext } from "react";
import axios, { AxiosResponse } from "axios";
import { RouteComponentProps, Link } from "@reach/router";

import UserSettingsContext from "../../context/user-settings-context";
import * as routes from "../../routes";

import agent from "../../agent";
import {
  IProfile,
  IAffiliation,
  IActivity,
  IAward,
  ICommunityActivity,
  IJob,
  IResume,
} from "../../interfaces";
import ViewDetails from "./ViewDetails";
import { Row, Col, Button, Alert } from "reactstrap";

const ViewResume: React.FC<RouteComponentProps> = props => {
  const { user } = useContext(UserSettingsContext);

  useEffect(() => {
    fetchAllResumeData();
  }, []);

  const [resumeData, setResumeData] = useState<IResume>({
    profile: null,
    affiliations: [],
    activities: [],
    awards: [],
    communityActivities: [],
    jobs: [],
  });
  function fetchAllResumeData() {
    // apparently TypeScript does not like axios.all and axios.spread
    axios
      .all([
        // @ts-ignore
        agent.Profile.get(),
        // @ts-ignore
        agent.GreekAffiliations.getAll(),
        // @ts-ignore
        agent.Activities.getAll(),
        // @ts-ignore
        agent.Awards.getAll(),
        // @ts-ignore
        agent.CommunityActivities.getAll(),
        // @ts-ignore
        agent.Jobs.getAll(),
      ])
      .then(
        // @ts-ignore
        axios.spread(function(
          profileRes: AxiosResponse<IProfile>,
          affiliationsRes: AxiosResponse<IAffiliation[]>,
          activitiesRes: AxiosResponse<IActivity[]>,
          awardsRes: AxiosResponse<IAward[]>,
          communityActivitiesRes: AxiosResponse<ICommunityActivity[]>,
          jobsRes: AxiosResponse<IJob[]>
        ) {
          setResumeData({
            profile: profileRes.data,
            affiliations: affiliationsRes.data,
            activities: activitiesRes.data,
            awards: awardsRes.data,
            communityActivities: communityActivitiesRes.data,
            jobs: jobsRes.data,
          });
        })
      );
  }

  function print() {
    if (typeof window !== `undefined` && typeof window.print === `function`) {
      window.print();
    } else {
      console.warn("Could not print, window is not available.");
    }
  }

  const [alert, setAlert] = useState<{ color: string; body: string } | null>(
    null
  );
  function downloadFile() {
    agent.Profile.getResumeDownloadUrl(user.id)
      .then(res => {
        if (res.data.resume_download_url) {
          window.open(res.data.resume_download_url);
        } else {
          setAlert({
            color: "warning",
            body: "Could not download resume, make sure it is complete.",
          });
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
  return (
    <>
      <span className="d-print-none">
        <Row>
          <Col>
            <h3>View Your Resumé</h3>
          </Col>
          <Col sm="auto">
            <Button
              tag={Link}
              to={routes.toResumeEditPersonal}
              className="btn-slim mr-2"
            >
              <i className="fas fa-pencil-alt mr-2" /> Edit
            </Button>
            <Button onClick={print} className="mr-2 btn-slim">
              <i className="fas fa-print mr-2" /> Print
            </Button>
            <Button className="btn-slim" onClick={downloadFile}>
              <i className="fas fa-download mr-2" /> Download
            </Button>
          </Col>
        </Row>
        <Alert
          className="my-2"
          color={alert ? alert.color : "info"}
          isOpen={alert !== null}
        >
          {alert ? alert.body : ""}
        </Alert>
        <hr />
      </span>
      <ViewDetails resume={resumeData} />
    </>
  );
};

export default ViewResume;
