import React from "react";
import { Link } from "@reach/router";
import { Row, Col, Button } from "reactstrap";

import * as routes from "../../routes";
import { IProfile } from "../../interfaces";

interface IEditHeaderProps {
  className?: string;
  currentStepTitle: string;
  profile: IProfile;
}

const EditHeader: React.FC<IEditHeaderProps> = props => {
  return (
    <>
      <Row>
        <Col>
          <h3>{props.currentStepTitle}</h3>
        </Col>
        <Col sm="auto">
          <Button tag={Link} to={routes.toResumeView} className="btn-slim mr-2">
            <i className="fas fa-eye mr-2" /> View &amp; Print
          </Button>
        </Col>
      </Row>
      <hr />
    </>
  );
};

export default EditHeader;
