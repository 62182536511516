export const EMPTY_USER = {
  confirmed_at: null,
  email: "",
  first_name: "",
  id: 0,
  last_name: "",
  paid: false,
  university_id: 0,
  payment_info: null,
  user_type: "",
};

export const signUpSteps = [
  {
    smallText: "Create your",
    bigText: "Account",
  },
  {
    smallText: "Enter your",
    bigText: "Payment",
  },
  {
    smallText: "And you're",
    bigText: "Done!",
  },
];

export const rwSignUpSteps = [
  {
    smallText: "Create your",
    bigText: "Account",
  },
  {
    smallText: "And you're",
    bigText: "Done!",
  },
];

export const MAX_SIZE_IN_BYTES = 10485760;
export const PHOTO_RESIZE_DIMENSIONS = 800;
