import React, { useContext, SyntheticEvent } from "react";
import dayjs from "dayjs";

import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";

import {
  Alert,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import {
  IRecInvite,
  ISorority,
  IApiResult,
  IProfile,
  IDashboardStats,
} from "../interfaces";
import UserSettingsContext from "../context/user-settings-context";

import {
  buildInvitationFormStarterText,
  buildInvitationFormStarterSignatureText,
} from "../utils/formHelpers";

const formSchema = yup.object().shape({
  writer_name: yup
    .string()
    .label("Rec Writer Name")
    .required(),
  writer_email: yup
    .string()
    .label("Rec Writer Email")
    .required(),
  subject: yup
    .string()
    .label("Subject")
    .required(),
  message: yup
    .string()
    .label("Message")
    .required(),
});

interface IRecInviteFormProps {
  className?: string;
  inviteInstructions?: string;
  isOpen: boolean;
  invite: IRecInvite;
  sorority: ISorority;
  onClose(): void;
  onSaveForLater(i: IRecInvite): Promise<IApiResult>;
  onSend(i: IRecInvite): Promise<IApiResult>;
  userUniversityName: string;
  profile: IProfile;
  stats: IDashboardStats;
}

const InvitationFormModal: React.FC<IRecInviteFormProps> = props => {
  const { user } = useContext(UserSettingsContext);

  function saveForLater(event: SyntheticEvent, values: IRecInvite) {
    event.preventDefault();
    const inviteToSave = {
      ...props.invite,
      ...values,
    };
    props.onSaveForLater(inviteToSave).then(() => {
      props.onClose();
    });
  }

  function handleSubmit(
    values: IRecInvite,
    actions: FormikActions<IRecInvite>
  ) {
    const inviteToSend = {
      ...props.invite,
      ...values,
      message: `
${values.message}

${values.signature}
`,
    };
    props.onSend(inviteToSend).then(() => {
      actions.setSubmitting(false);
      props.onClose();
    });
  }

  const isSent = !!props.invite.sent_at;
  const allowedToSend =
    props.stats.resume.complete === 100 &&
    (props.stats.files.profile_photo || props.stats.files.photo);

  const fullName = `${user.first_name} ${user.last_name}`;

  function getSignature(): string {
    if (
      props.invite.signature !== null &&
      typeof props.invite.signature !== "undefined"
    ) {
      return props.invite.signature;
    } else if (props.invite.message.length > 0) {
      return "";
    }
    return buildInvitationFormStarterSignatureText(
      props.profile.name || fullName
    );
  }
  return (
    <Formik
      initialValues={{
        ...props.invite,
        subject:
          props.invite.subject.length > 0
            ? props.invite.subject
            : `${props.profile.name}'s recommendation request for ${
                props.sorority.sorority_name
              }`,
        message:
          props.invite.message.length > 0
            ? props.invite.message
            : buildInvitationFormStarterText(
                props.userUniversityName,
                props.sorority.sorority_name
              ),
        signature: getSignature(),
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<IRecInvite>) => (
        <Modal
          size="lg"
          isOpen={props.isOpen}
          toggle={props.onClose}
          className={props.className}
        >
          <Form onSubmit={formikBag.handleSubmit}>
            <ModalHeader toggle={props.onClose}>
              Rec Invite for {props.sorority.sorority_name}
              {props.inviteInstructions && (
                <p style={{ fontSize: "1rem" }}>{props.inviteInstructions}</p>
              )}
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>Rec Writer Name</Label>
                <Input
                  name="writer_name"
                  disabled={isSent}
                  value={formikBag.values.writer_name}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.writer_name &&
                      formikBag.touched.writer_name
                    )
                  }
                />
                <FormFeedback>{formikBag.errors.writer_name}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Rec Writer Email</Label>
                <Input
                  name="writer_email"
                  disabled={isSent}
                  value={formikBag.values.writer_email}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(
                      formikBag.errors.writer_email &&
                      formikBag.touched.writer_email
                    )
                  }
                />
                <FormFeedback>{formikBag.errors.writer_email}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Subject</Label>
                <Input
                  name="subject"
                  disabled={isSent}
                  value={formikBag.values.subject}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(formikBag.errors.subject && formikBag.touched.subject)
                  }
                />
                <FormFeedback>{formikBag.errors.subject}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Message</Label>
                <Input
                  type="textarea"
                  rows={8}
                  name="message"
                  disabled={isSent}
                  value={formikBag.values.message}
                  onChange={formikBag.handleChange}
                  invalid={
                    !!(formikBag.errors.message && formikBag.touched.message)
                  }
                />
                <FormFeedback>{formikBag.errors.message}</FormFeedback>
              </FormGroup>
              {!isSent && (
                <FormGroup>
                  <Label>Signature</Label>
                  <Input
                    type="textarea"
                    rows={4}
                    name="signature"
                    value={formikBag.values.signature || ""}
                    onChange={formikBag.handleChange}
                    invalid={
                      !!(
                        formikBag.errors.signature &&
                        formikBag.touched.signature
                      )
                    }
                  />
                </FormGroup>
              )}
              <Alert isOpen={!isSent && !allowedToSend} color="danger">
                Before you can send invites, please complete your resumé and
                upload a photo on the Files tab.
              </Alert>
            </ModalBody>
            <ModalFooter className="d-flex">
              {!props.invite.sent_at && (
                <Button
                  color="secondary"
                  onClick={(event: SyntheticEvent) =>
                    saveForLater(event, formikBag.values)
                  }
                >
                  Save for later
                </Button>
              )}
              <Button
                className="ml-auto"
                type="submit"
                color="primary"
                disabled={!allowedToSend || isSent || formikBag.isSubmitting || !formikBag.values.writer_name || !formikBag.values.writer_email}
              >
                {isSent && props.invite.sent_at
                  ? `Sent on ${dayjs(props.invite.sent_at).format(
                      "MMM D, h:mm a"
                    )}`
                  : "Send now"}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    />
  );
};

export default InvitationFormModal;
