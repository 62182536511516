import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Router, RouteComponentProps } from "@reach/router";
import { Container, Row, Col } from "reactstrap";

import * as routes from "../routes";
import agent from "../agent";
import {
  IProfile,
  IContentfulPage,
  IUniversity,
  IAffiliation,
  IActivity,
  IAward,
  ICommunityActivity,
  IJob,
} from "../interfaces";

import Layout from "../components/Layout";
import Tip from "../components/Tip";
import View from "../components/resume/View";
import EditPersonal from "../components/resume/EditPersonal";
import EditEducation from "../components/resume/EditEducation";
import EditFamily from "../components/resume/EditFamily";
import EditGreekAffiliations from "../components/resume/EditGreekAffiliations";
import EditActivities from "../components/resume/EditActivities";
import EditAwards from "../components/resume/EditAwards";
import EditCommunityInvolvement from "../components/resume/EditCommunityInvolvement";
import EditEmployment from "../components/resume/EditEmployment";
import ResumeFormWrapper from "../components/resume/ResumeFormWrapper";

interface IResumeProps extends RouteComponentProps {
  pageContent?: IContentfulPage;
  universityList: IUniversity[];
}

const Resume: React.FC<IResumeProps> = props => {
  const { pageContent } = props;
  const [profile, setProfile] = useState<IProfile | null>(null);
  function getProfile() {
    agent.Profile.get().then(res => {
      if (res.data) {
        setProfile(res.data);
      }
    });
  }
  useEffect(() => {
    getProfile();
    getOptionalResumeData();
  }, []);

  const [greekAffiliations, setGreekAffiliations] = useState<
    IAffiliation[] | null
  >(null);
  const [activities, setActivities] = useState<IActivity[] | null>(null);
  const [awards, setAwards] = useState<IAward[] | null>(null);
  const [community, setCommunity] = useState<ICommunityActivity[] | null>(null);
  const [jobs, setJobs] = useState<IJob[] | null>(null);
  async function getOptionalResumeData() {
    try {
      const greekRes = await agent.GreekAffiliations.getAll();
      if (greekRes.data) {
        setGreekAffiliations(greekRes.data);
      }
      const activitiesRes = await agent.Activities.getAll();
      if (activitiesRes.data) {
        setActivities(activitiesRes.data);
      }
      const awardsRes = await agent.Awards.getAll();
      if (awardsRes.data) {
        setAwards(awardsRes.data);
      }
      const communityRes = await agent.CommunityActivities.getAll();
      if (communityRes.data) {
        setCommunity(communityRes.data);
      }
      const jobsRes = await agent.Jobs.getAll();
      if (jobsRes.data) {
        setJobs(jobsRes.data);
      }
    } catch (e) {
      console.error("Error fetching optional resume data", e);
    }
  }

  function handleSubmit(updatedProfile: IProfile) {
    return agent.Profile.update(updatedProfile)
      .then(res => {
        if (res.data) {
          setProfile(res.data);
        }
        return res;
      })
      .catch(err => {
        if (err.response) {
          return err.response;
        }
      });
  }

  function updateProfile(updatedProfile: IProfile) {
    setProfile(updatedProfile);
    getOptionalResumeData();
  }

  // the PNM user can indicate that they are finished editing
  // which will mark the resume as "complete" and should disable editing
  const [resumeEditDisabled, setResumeEditDisabled] = useState(false);
  useEffect(() => {
    if (profile && profile.complete) {
      setResumeEditDisabled(true);
    } else {
      setResumeEditDisabled(false);
    }
  }, [profile]);

  return (
    <Layout>
      {profile && (
        <Container className="rh-main-container">
          <span className="d-print-none">
            <h1>
              {pageContent && pageContent.title ? pageContent.title : "Resumé"}
            </h1>
            {pageContent && pageContent.instructions && (
              <ReactMarkdown
                source={pageContent.instructions}
                className="mb-5"
              />
            )}
          </span>
          <div className="mb-4">
            <Router>
              <View path={routes.resumeViewPath} />
              <ResumeFormWrapper
                default
                profile={profile}
                reloadPhoto={getProfile}
                greekAffiliations={greekAffiliations}
                activities={activities}
                awards={awards}
                community={community}
                jobs={jobs}
                resumeEditDisabled={resumeEditDisabled}
                onChangeResumeEditState={newState =>
                  handleSubmit({
                    ...profile,
                    complete: !newState.allowEdit,
                  }).then(() => {
                    setResumeEditDisabled(!newState.allowEdit);
                  })
                }
              >
                <EditPersonal
                  default
                  path={routes.resumeEditPersonalPath}
                  profile={profile}
                  onSubmit={handleSubmit}
                  formIsDisabled={resumeEditDisabled}
                />
                <EditEducation
                  path={routes.resumeEditEducationPath}
                  profile={profile}
                  onSubmit={handleSubmit}
                  universityList={props.universityList}
                  formIsDisabled={resumeEditDisabled}
                />
                <EditFamily
                  path={routes.resumeEditFamilyPath}
                  profile={profile}
                  onSubmit={handleSubmit}
                  formIsDisabled={resumeEditDisabled}
                />
                <EditGreekAffiliations
                  path={routes.resumeEditGreekAffiliationsPath}
                  profile={profile}
                  onUpdateProfile={updateProfile}
                  onSubmit={handleSubmit}
                  formIsDisabled={resumeEditDisabled}
                />
                <EditActivities
                  path={routes.resumeEditActivitiesPath}
                  profile={profile}
                  onUpdateProfile={updateProfile}
                  onSubmit={handleSubmit}
                  formIsDisabled={resumeEditDisabled}
                />
                <EditAwards
                  path={routes.resumeEditAwardsPath}
                  profile={profile}
                  onUpdateProfile={updateProfile}
                  onSubmit={handleSubmit}
                  formIsDisabled={resumeEditDisabled}
                />
                <EditCommunityInvolvement
                  path={routes.resumeEditCommunityInvolvementPath}
                  profile={profile}
                  onUpdateProfile={updateProfile}
                  onSubmit={handleSubmit}
                  formIsDisabled={resumeEditDisabled}
                />
                <EditEmployment
                  path={routes.resumeEditEmploymentPath}
                  profile={profile}
                  onUpdateProfile={updateProfile}
                  onSubmit={handleSubmit}
                  formIsDisabled={resumeEditDisabled}
                  greekAffiliations={greekAffiliations}
                  activities={activities}
                  awards={awards}
                  community={community}
                  jobs={jobs}
                />
              </ResumeFormWrapper>
            </Router>
          </div>
          <Row className="d-print-none">
            {pageContent &&
              pageContent.tips &&
              pageContent.tips.map((tip, index) => (
                <Col key={index} md="4" className="mb-3 mb-md-0">
                  <Tip text={tip} />
                </Col>
              ))}
          </Row>
        </Container>
      )}
    </Layout>
  );
};

export default Resume;
