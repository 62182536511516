import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { RouteComponentProps } from "@reach/router";
import {
  Container,
  CustomInput,
  Table,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Input,
  Label,
  Alert,
  Button,
} from "reactstrap";

import agent from "../agent";
import Layout from "../components/Layout";
import Tip from "../components/Tip";
import { IContentfulPage, IFile, FileTypes } from "../interfaces";

import FileUpload from "../components/FileUpload";
import { humanize, resizeImage, blobToFile } from "../utils/genericUtils";
import { MAX_SIZE_IN_BYTES, PHOTO_RESIZE_DIMENSIONS } from "../constants";

const FileMap: { [key: string]: string } = {
  "application/pdf": "PDF",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "DOCX",
  "image/png": "PNG",
  "image/jpeg": "JPG",
};

function formatFileToUi(file: IFile) {
  return {
    id: file.id,
    type:
      file.type === "generated_resume" ? "RecHub Resume" : humanize(file.type),
    name: file.filename,
  };
}

interface IFilesProps extends RouteComponentProps {
  pageContent?: IContentfulPage;
}

const Files: React.FC<IFilesProps> = props => {
  const { pageContent } = props;
  const [files, setFiles] = useState<
    Array<{
      type: string;
      name: string;
      id: number;
    }>
  >([]);

  useEffect(() => {
    fetchFiles();
  }, []);

  function fetchFiles() {
    agent.Files.getAll().then(res => {
      if (!res.data) return;
      setFiles(res.data.map(formatFileToUi));
    });
  }

  const [filesToDelete, setFilesToDelete] = useState<number[]>([]);

  function toggleDeleteFile(fileId: number) {
    const index = filesToDelete.indexOf(fileId);
    if (index !== -1) {
      setFilesToDelete([
        ...filesToDelete.slice(0, index),
        ...filesToDelete.slice(index + 1),
      ]);
    } else {
      setFilesToDelete([...filesToDelete, fileId]);
    }
  }

  function deleteFiles() {
    agent.Files.deleteAll(filesToDelete)
      .then(() => {
        setFilesToDelete([]);
        fetchFiles();
      })
      .catch(() => {
        setError(
          "We had an issue deleting some files, please refresh and try again."
        );
        fetchFiles();
      });
  }

  const [selectedFileType, setSelectedFileType] = useState<FileTypes | "">("");

  const [error, setError] = useState<string | null>(null);

  const [filesDropdownToggle, setFilesDropdownToggle] = useState<boolean>(
    false
  );

  return (
    <Layout>
      <Container className="rh-main-container">
        <h1>
          {pageContent && pageContent.title ? pageContent.title : "Files"}
        </h1>
        {pageContent && pageContent.instructions && (
          <ReactMarkdown source={pageContent.instructions} className="mb-5" />
        )}
        {error && (
          <Row>
            <Col>
              <Alert
                isOpen={error !== ""}
                toggle={() => setError("")}
                color="danger"
              >
                {error}
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex">
                <Dropdown
                  isOpen={filesDropdownToggle}
                  toggle={() => {
                    setFilesDropdownToggle(!filesDropdownToggle);
                  }}
                >
                  <DropdownToggle color="primary">Upload File</DropdownToggle>
                  <DropdownMenu>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr minmax(165px, 1fr)",
                      }}
                    >
                      <div>
                        <DropdownItem header>
                          Select an upload type
                        </DropdownItem>
                        <div className="px-4 py-1">
                          <Input
                            type="select"
                            name="fileTypeSelect"
                            id="fileTypeSelect"
                            bsSize="sm"
                            value={selectedFileType}
                            onChange={e => {
                              e.stopPropagation();
                              setSelectedFileType(e.target.value as FileTypes);
                            }}
                          >
                            <option disabled selected value="" />
                            {Object.keys(FileTypes)
                              .filter(
                                ft =>
                                  ft !== "profile_photo" &&
                                  ft !== "generated_resume"
                              )
                              .map(ft => (
                                <option
                                  key={ft}
                                  value={ft}
                                >{`${ft[0].toUpperCase()}${ft.slice(
                                  1
                                )}`}</option>
                              ))}
                          </Input>
                        </div>
                        <DropdownItem header>
                          Choose a file from...
                        </DropdownItem>
                        <DropdownItem
                          className="btn btn-primary"
                          style={{
                            background:
                              selectedFileType === ""
                                ? "var(--white)"
                                : "var(--primary)",
                            color:
                              selectedFileType === ""
                                ? "var(--gray-dark)"
                                : "var(--white)",
                            cursor: "pointer",
                            padding: 0,
                            marginLeft: "4px",
                          }}
                        >
                          <FileUpload
                            onSuccess={({ data }) => {
                              setFilesDropdownToggle(false);
                              setFiles([
                                ...(files || []),
                                formatFileToUi(data),
                              ]);
                            }}
                            onError={() => {
                              setFilesDropdownToggle(false);
                              setError(
                                "Error uploading file, please try again."
                              );
                            }}
                            selectedFileType={selectedFileType}
                            render={({
                              handleUpload,
                              uploads,
                              ready,
                            }: {
                              handleUpload: Function;
                              uploads: any[];
                              ready: boolean;
                            }) => (
                              <div>
                                <Label
                                  disabled={!ready || selectedFileType === ""}
                                  onClick={e => e.stopPropagation()}
                                  for="fileUpload"
                                >
                                  Your Computer
                                  <Input
                                    hidden
                                    name="fileUpload"
                                    id="fileUpload"
                                    type="file"
                                    disabled={!ready || selectedFileType === ""}
                                    onChange={e => {
                                      const {
                                        currentTarget: { files },
                                      } = e;
                                      e.stopPropagation();
                                      if (!files) {
                                        return;
                                      }
                                      if (
                                        Array.from(files).some(file => {
                                          return (
                                            file.size > MAX_SIZE_IN_BYTES ||
                                            !FileMap[file.type]
                                          );
                                        })
                                      ) {
                                        setFilesDropdownToggle(false);
                                        setError(
                                          "Files must be under 10MB and in PNG, JPG, DOCX, or PDF format."
                                        );
                                        return;
                                      }
                                      if (selectedFileType === "photo") {
                                        console.log(
                                          "orig file size:",
                                          files[0].size
                                        );

                                        resizeImage({
                                          file: files[0],
                                          maxSize: PHOTO_RESIZE_DIMENSIONS,
                                        }).then((r: any) => {
                                          const resized = blobToFile(
                                            r,
                                            files[0].name
                                          );

                                          console.log(
                                            "resized file size:",
                                            resized.size
                                          );
                                          handleUpload([resized]);
                                        });
                                      } else {
                                        handleUpload(files);
                                      }
                                    }}
                                  />
                                </Label>
                                {/* eslint-disable-next-line */}
                                {uploads.map(upload => {
                                  switch (upload.state) {
                                    case "waiting":
                                      return (
                                        <p key={upload.id}>
                                          Waiting to upload {upload.file.name}
                                        </p>
                                      );
                                    case "uploading":
                                      return (
                                        <p key={upload.id}>
                                          Uploading {upload.file.name}:{" "}
                                          {Math.round(upload.progress)}%
                                        </p>
                                      );
                                    case "error":
                                      return (
                                        <p key={upload.id}>
                                          Error uploading {upload.file.name}:{" "}
                                          {upload.error}
                                        </p>
                                      );
                                    case "finished":
                                      return (
                                        <p key={upload.id}>
                                          Finished uploading {upload.file.name}
                                        </p>
                                      );
                                  }
                                })}
                              </div>
                            )}
                          />
                        </DropdownItem>
                      </div>
                      <div className="pl-3 m-2 border-left border-dark d-flex align-items-center">
                        <small className="text-secondary">
                          We accept PNG, JPG, DOCX, PDF documents up to 10MB.
                        </small>
                      </div>
                    </div>
                  </DropdownMenu>
                </Dropdown>
                {filesToDelete.length > 0 && (
                  <Button
                    color="secondary"
                    className="ml-2"
                    onClick={() => deleteFiles()}
                  >
                    Delete Files
                  </Button>
                )}
              </div>
              <p className="mb-0 text-muted">
                <small>
                  Showing: {files.length > 0 ? `1 to ${files.length}` : 0} Files
                </small>
              </p>
            </div>
            <Table>
              <thead>
                <tr>
                  <th>
                    <i className="fas fa-trash-alt" />
                  </th>
                  <th>Type</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {files &&
                  files.map(file => (
                    <tr key={file.id}>
                      <th scope="row">
                        <CustomInput
                          type="checkbox"
                          onClick={() => toggleDeleteFile(file.id)}
                          id={`selectFile-${file.id}`}
                        />
                      </th>
                      <td>{file.type}</td>
                      <td>{file.name}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
          <Col lg="3" className="d-none d-lg-block">
            {pageContent &&
              pageContent.tips &&
              pageContent.tips.map((tip, index) => (
                <Tip key={index} text={tip} className="mb-3" />
              ))}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Files;
