export const monthOptions = [
  {
    value: 1,
    label: "01 - Jan",
  },
  {
    value: 2,
    label: "02 - Feb",
  },
  {
    value: 3,
    label: "03 - Mar",
  },
  {
    value: 4,
    label: "04 - Apr",
  },
  {
    value: 5,
    label: "05 - May",
  },
  {
    value: 6,
    label: "06 - Jun",
  },
  {
    value: 7,
    label: "07 - Jul",
  },
  {
    value: 8,
    label: "08 - Aug",
  },
  {
    value: 9,
    label: "09 - Sep",
  },
  {
    value: 10,
    label: "10 - Oct",
  },
  {
    value: 11,
    label: "11 - Nov",
  },
  {
    value: 12,
    label: "12 - Dec",
  },
];

export const capitalizeWord = (word: string) =>
  `${word[0].toUpperCase()}${word.slice(1)}`;

export const futureYearOptions = (yearsInFuture = 10) => {
  const currentYear = new Date().getFullYear();
  let options = [currentYear];
  for (let i = 1; i <= yearsInFuture; i++) {
    options = [...options, currentYear + i];
  }
  return options;
};

export const pastYearOptions = (yearsInPast = 10) => {
  const currentYear = new Date().getFullYear();
  let options = [currentYear];
  for (let i = 1; i <= yearsInPast; i++) {
    options = [...options, currentYear - i];
  }
  return options;
};

export const buildInvitationFormStarterText = function(
  universityName: string,
  sororityName: string
) {
  return `Hi there!

Thanks so much for offering to write me a recommendation for ${sororityName}! I am so excited to be attending ${universityName} this fall.

To make it easier for my recommendation writers, I’m using a new web app called RecHub that helps manage my letters of recommendation. RecHub is a central platform that has built-in reminders and notifications to help throughout the process. 
  
Click the big orange button at the bottom of this email to see my resume, transcript, photos and everything else you'll need to write my rec. You'll have to register if you don't already have an account, but it is quick and painless.
  
I look forward to finding sisterhood that feels like a home away from home and thank you again for your support and recommendation for membership.`;
};

export const buildInvitationFormStarterSignatureText = function(
  displayName: string
) {
  return `Best,

${displayName}`;
};

export const stateOptions = [
  {
    code: "AL",
    label: "Alabama",
  },
  {
    code: "AK",
    label: "Alaska",
  },
  {
    code: "AS",
    label: "American Samoa",
  },
  {
    code: "AZ",
    label: "Arizona",
  },
  {
    code: "AR",
    label: "Arkansas",
  },
  {
    code: "CA",
    label: "California",
  },
  {
    code: "CO",
    label: "Colorado",
  },
  {
    code: "CT",
    label: "Connecticut",
  },
  {
    code: "DE",
    label: "Delaware",
  },
  {
    code: "DC",
    label: "District Of Columbia",
  },
  {
    code: "FM",
    label: "Federated States Of Micronesia",
  },
  {
    code: "FL",
    label: "Florida",
  },
  {
    code: "GA",
    label: "Georgia",
  },
  {
    code: "GU",
    label: "Guam",
  },
  {
    code: "HI",
    label: "Hawaii",
  },
  {
    code: "ID",
    label: "Idaho",
  },
  {
    code: "IL",
    label: "Illinois",
  },
  {
    code: "IN",
    label: "Indiana",
  },
  {
    code: "IA",
    label: "Iowa",
  },
  {
    code: "KS",
    label: "Kansas",
  },
  {
    code: "KY",
    label: "Kentucky",
  },
  {
    code: "LA",
    label: "Louisiana",
  },
  {
    code: "ME",
    label: "Maine",
  },
  {
    code: "MH",
    label: "Marshall Islands",
  },
  {
    code: "MD",
    label: "Maryland",
  },
  {
    code: "MA",
    label: "Massachusetts",
  },
  {
    code: "MI",
    label: "Michigan",
  },
  {
    code: "MN",
    label: "Minnesota",
  },
  {
    code: "MS",
    label: "Mississippi",
  },
  {
    code: "MO",
    label: "Missouri",
  },
  {
    code: "MT",
    label: "Montana",
  },
  {
    code: "NE",
    label: "Nebraska",
  },
  {
    code: "NV",
    label: "Nevada",
  },
  {
    code: "NH",
    label: "New Hampshire",
  },
  {
    code: "NJ",
    label: "New Jersey",
  },
  {
    code: "NM",
    label: "New Mexico",
  },
  {
    code: "NY",
    label: "New York",
  },
  {
    code: "NC",
    label: "North Carolina",
  },
  {
    code: "ND",
    label: "North Dakota",
  },
  {
    code: "MP",
    label: "Northern Mariana Islands",
  },
  {
    code: "OH",
    label: "Ohio",
  },
  {
    code: "OK",
    label: "Oklahoma",
  },
  {
    code: "OR",
    label: "Oregon",
  },
  {
    code: "PW",
    label: "Palau",
  },
  {
    code: "PA",
    label: "Pennsylvania",
  },
  {
    code: "PR",
    label: "Puerto Rico",
  },
  {
    code: "RI",
    label: "Rhode Island",
  },
  {
    code: "SC",
    label: "South Carolina",
  },
  {
    code: "SD",
    label: "South Dakota",
  },
  {
    code: "TN",
    label: "Tennessee",
  },
  {
    code: "TX",
    label: "Texas",
  },
  {
    code: "UT",
    label: "Utah",
  },
  {
    code: "VT",
    label: "Vermont",
  },
  {
    code: "VI",
    label: "Virgin Islands",
  },
  {
    code: "VA",
    label: "Virginia",
  },
  {
    code: "WA",
    label: "Washington",
  },
  {
    code: "WV",
    label: "West Virginia",
  },
  {
    code: "WI",
    label: "Wisconsin",
  },
  {
    code: "WY",
    label: "Wyoming",
  },
];

export const formatDate = (dateString: string | null): string => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const d = date.getUTCDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();

  return `${y}-${m}-${d}`;
};
