import React from "react";
import ReactDOM from "react-dom";
import "./styles.scss";
import * as serviceWorker from "./serviceWorker";

import App from "./App";

// some invite emails had the netlify URL so this is to prevent RWs
// winding up on the netlify site without being able to sign up

const stagePart = new RegExp(/myrechub-staging.netlify.app/);
const prodPart = new RegExp(/rechub.netlify.app/);
if (window.location.href.match(prodPart)) {
  window.location.href = window.location.href.replace(
    prodPart,
    "app.myrechub.com"
  );
}
if (window.location.href.match(stagePart)) {
  window.location.href = window.location.href.replace(
    stagePart,
    "app-staging.myrechub.com"
  );
}
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
