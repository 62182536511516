import React from "react";
import { Button } from "reactstrap";
import fileDownload from "js-file-download";
import agent from "../agent";

interface Props {
  recId: number;
  updateRecRequest(): void;
}

const FileDownload = ({ recId, updateRecRequest }: Props) => {
  return (
    <Button
      color="primary"
      onClick={() => {
        agent.Files.downloadRecFiles(recId)
          .then(response => {
            fileDownload(response.data, "files.zip");
          })
          .then(updateRecRequest);
      }}
    >
      Download All
    </Button>
  );
};

export default FileDownload;
