import React from "react";
import ReactMarkdown from "react-markdown";
import { RouteComponentProps } from "@reach/router";
import { IContentfulPage } from "../interfaces";
import { Container, Row, Col } from "reactstrap";
import Layout from "../components/Layout";

interface IPrivacyProps extends RouteComponentProps {
  pageContent?: IContentfulPage;
}

const Privacy: React.FC<IPrivacyProps> = ({ pageContent }) => (
  <Layout>
    <Container className="rh-main-container">
      <Row>
        <Col xs={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }}>
          {pageContent && <ReactMarkdown source={pageContent.instructions} />}
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default Privacy;
