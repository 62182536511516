import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Card, CardBody, Col, Form, Row } from "reactstrap";
import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";
import dropin from "braintree-web-drop-in";

import { IBraintreeRequestPaymentResponse, IUser } from "../interfaces";

const formSchema = yup.object().shape({
  discountCode: yup.string().label("Discount code"),
});

interface IPaymentFormValues {
  discountCode?: string;
}

interface IPaymentFormProps {
  token: string;
  price: string | number | null;
  errorMessage?: string;
  user: IUser;
  onRequestPayment(payload: IBraintreeRequestPaymentResponse): void;
}

const PaymentForm: React.FC<IPaymentFormProps> = props => {
  const [dropinInstance, setDropinInstance] = useState<any>(null);
  const dropinEl: any = useRef(null);
  useEffect(() => {
    dropin.create(
      {
        authorization: props.token,
        container: "#braintree-dropin-container",
      },
      function(createErr: any, instance: any) {
        setDropinInstance(instance);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSubmit(
    values: IPaymentFormValues,
    actions: FormikActions<IPaymentFormValues>
  ) {
    if (dropinInstance) {
      dropinInstance.requestPaymentMethod(function(
        requestPaymentMethodErr: any,
        payload: any
      ) {
        if (requestPaymentMethodErr) {
          console.error("requestPaymentMethodErr", requestPaymentMethodErr);
        } else {
          actions.setSubmitting(false);
          props.onRequestPayment(payload);
        }
      });
    }
  }

  return (
    <Formik
      initialValues={{
        cardName: "",
        cardNumber: "",
        expirationMonth: "",
        expirationYear: "",
        cvvCode: "",
        billingZipCode: "",
        cardPhoneNumber: "",
        discountCode: "",
      }}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<IPaymentFormValues>) => (
        <Form onSubmit={formikBag.handleSubmit}>
          <Row>
            <Col sm="6">
              <Card className="rh-bg-teal border-0 h-100">
                <CardBody>
                  <h2>Payment Method</h2>
                  <div id="braintree-dropin-container" ref={dropinEl} />
                  {props.errorMessage && (
                    <Alert className="my-3" color="danger">
                      {props.errorMessage}
                    </Alert>
                  )}
                  <p className="mb-0">
                    Get started and order confirmation links will be sent to:
                    <br />
                    {props.user.email}
                    <br />
                    (You can always change this later in Settings after sign up)
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card className="rh-bg-teal border-0 h-100">
                <CardBody>
                  <h2>You're Purchasing</h2>
                  <hr />
                  <div className="d-flex">
                    <div className="mr-auto">
                      <p className="h5 mb-0">Rec Hub Season Pass</p>
                      <p className="mb-0">
                        <small>Seasonal Plan (9 months)</small>
                      </p>
                    </div>
                    <div>
                      <p className="h1 mb-0">${props.price}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="text-center">
                    <Button
                      type="submit"
                      color="primary"
                      disabled={formikBag.isSubmitting}
                      className="mb-2"
                    >
                      Place Secure Order
                    </Button>
                    <p className="text-primary">
                      <i className="fas fa-lock" /> Secure credit card payment
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default PaymentForm;
