import React from "react";

import { Link } from "@reach/router";

interface ILinkRouteAwareProps {
  className?: string;
  to?: string;
}

const LinkRouteAware: React.FC<ILinkRouteAwareProps> = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: `${props.className} ${isCurrent ? "active" : ""}`,
      };
    }}
  />
);

export default LinkRouteAware;
