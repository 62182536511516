import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { RouteComponentProps } from "@reach/router";

import * as routes from "../../routes";

import LinkRouteAware from "../LinkRouteAware";
import EditHeader from "./EditHeader";
import EditPhoto from "./EditPhoto";

import {
  IProfile,
  IAffiliation,
  IActivity,
  IAward,
  ICommunityActivity,
  IJob,
  IStep,
} from "../../interfaces";

interface IResumeFormWrapperProps extends RouteComponentProps {
  className?: string;
  profile: IProfile;
  greekAffiliations: IAffiliation[] | null;
  activities: IActivity[] | null;
  awards: IAward[] | null;
  community: ICommunityActivity[] | null;
  jobs: IJob[] | null;
  resumeEditDisabled: boolean;
  reloadPhoto(): void;
  onChangeResumeEditState(state: { allowEdit: boolean }): void;
}

const ResumeFormWrapper: React.FC<IResumeFormWrapperProps> = props => {
  const steps: IStep[] = [
    {
      name: "Personal",
      to: routes.toResumeEditPersonal,
      isComplete: !!props.profile.street_address,
    },
    {
      name: "Education",
      to: routes.toResumeEditEducation,
      isComplete: !!props.profile.intended_major,
    },
    {
      name: "Family",
      to: routes.toResumeEditFamily,
      isComplete: !!props.profile.mother_name,
    },
    {
      name: "Greek Affiliations",
      to: routes.toResumeEditGreekAffiliations,
      isComplete:
        props.profile.opt_out_greek_affiliations ||
        (props.greekAffiliations ? props.greekAffiliations.length > 0 : false),
    },
    {
      name: "Activities & Interests",
      to: routes.toResumeEditActivities,
      isComplete:
        props.profile.opt_out_activities ||
        (props.activities ? props.activities.length > 0 : false),
    },
    {
      name: "Awards & Honors",
      to: routes.toResumeEditAwards,
      isComplete:
        props.profile.opt_out_awards ||
        (props.awards ? props.awards.length > 0 : false),
    },
    {
      name: "Community Involvement",
      to: routes.toResumeEditCommunityInvolvement,
      isComplete:
        props.profile.opt_out_community_activities ||
        (props.community ? props.community.length > 0 : false),
    },
    {
      name: "Employment",
      to: routes.toResumeEditEmployment,
      isComplete:
        props.profile.opt_out_jobs ||
        (props.jobs ? props.jobs.length > 0 : false),
    },
  ];

  const [title, setTitle] = useState("");
  useEffect(() => {
    const currentStepIndex = steps.findIndex(
      s => s.to === (props.location ? props.location.pathname : "")
    );
    if (currentStepIndex !== -1) {
      setTitle(`Step ${currentStepIndex + 1}: ${steps[currentStepIndex].name}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location]);

  return (
    <>
      <EditHeader profile={props.profile} currentStepTitle={title} />
      <Row>
        <Col sm="auto" className="d-none d-sm-block text-center">
          <EditPhoto
            photoUrl={props.profile.profile_photo_url}
            reloadPhoto={props.reloadPhoto}
          />
        </Col>
        <Col>{props.children}</Col>
        <Col sm="auto" className="d-none d-sm-block">
          <h4 className="mb-3">Steps: Build a Profile</h4>
          <ul className={`list-unstyled ${props.className}`}>
            {steps.map((step, index) => (
              <li key={step.name}>
                <LinkRouteAware
                  to={step.to}
                  className={`rh-step-link d-flex align-items-center mb-3 ${
                    step.isComplete ? "is-complete" : ""
                  }`}
                >
                  <div
                    className="rh-step-number rounded-circle d-flex align-items-center justify-content-center h3 m-0 mr-2"
                    style={{ height: "40px", width: "40px" }}
                  >
                    {step.isComplete ? (
                      <span>
                        <i className="fas fa-check fa-sm" />
                      </span>
                    ) : (
                      <span>{index + 1}</span>
                    )}
                  </div>
                  <p className="rh-step-text mb-0">{step.name}</p>
                </LinkRouteAware>
              </li>
            ))}
            <hr />
            <li
              className={`d-flex align-items-center ${
                props.profile.complete ? "text-info" : "text-light"
              }`}
            >
              {props.profile.complete ? (
                <span>
                  <i className="fas fa-check-circle mx-2" />
                </span>
              ) : (
                <div
                  className="rounded-circle bg-light mx-3"
                  style={{ height: "15px", width: "15px" }}
                />
              )}
              <p className="h5 mb-0">Resumé Complete!</p>
            </li>
            {props.resumeEditDisabled ? (
              <p className="mt-3">
                Need to make changes? {/* eslint-disable-next-line */}
                <a
                  className="text-underline text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props.onChangeResumeEditState({ allowEdit: true })
                  }
                >
                  Edit Resume
                </a>
              </p>
            ) : (
              <p className="mt-3">
                All done editing? {/* eslint-disable-next-line */}
                <a
                  className="text-underline text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props.onChangeResumeEditState({ allowEdit: false })
                  }
                >
                  Finish
                </a>
              </p>
            )}
          </ul>
        </Col>
      </Row>
    </>
  );
};

export default ResumeFormWrapper;
