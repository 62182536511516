import axios from "axios";

import {
  IUniversity,
  IUser,
  IRecInviteList,
  IRecInvite,
  IProfile,
  IDashboardStats,
  IAccountSettings,
  IAffiliation,
  IActivity,
  IAward,
  ICommunityActivity,
  IJob,
  IUserRegistration,
  IFile,
  IRecRequest,
  IRecRequestSummary,
  INotification,
} from "./interfaces";

export const API_BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = API_BASE_URL;
const COOKIE = "SAFARI_TEST";
const Auth = {
  login: (email: string, password: string) => {
    return axios
      .post(`session`, {
        email,
        password,
      })
      .then(res => {
        console.log(res);
        return res;
      });
  },
  logout: () => axios.delete(`session`),
  resetPassword: (email_address: string) => {
    return axios.post(`pw_reset`, {
      email_address,
    });
  },
  verifyResetToken: (token: string) => {
    return axios.get(`pw_reset/${token}`);
  },
  changePassword: (new_password: string, token: string) => {
    return axios.post(`pw_reset/${token}`, {
      new_password,
    });
  },
  confirmEmail: (token: string) => {
    return axios.post(`confirm_email/${token}`);
  },
  getUser: () => axios.get<IUser>(`hello`),
  setCookie: () => axios.get(`cookie-check/set?cookie_value=${COOKIE}`),
  checkCookie: () =>
    axios.get<{ cookie_match: boolean }>(
      `cookie-check/test?check_cookie_value="${COOKIE}`
    ),
};

const Files = {
  handleAttachment: (signed_id: string, fileType: string) => {
    return axios.post<IFile>(`profile/files`, {
      type: fileType,
      signed_id,
    });
  },
  getAll: () => axios.get<IFile[]>(`/profile/files`),
  downloadRecFiles: (id: number) =>
    axios.get<any>(`/writer_recs/${id}/files`, { responseType: "blob" }),
  deleteAll: (fileIds: number[]) => {
    return Promise.all(fileIds.map(id => axios.delete(`/profile/files/${id}`)));
  },
};

const User = {
  createAccount: (formData: IUserRegistration) =>
    axios.post<IUser>(`signup`, {
      ...formData,
    }),
  updatePassword: (password: string) =>
    axios.patch(`settings`, {
      password,
    }),
  getSettings: () => axios.get<IAccountSettings>(`settings`),
  updateSettings: (accountData: IAccountSettings) =>
    axios.put<IAccountSettings>(`settings`, accountData),
};

const Dashboard = {
  get: () => axios.get<IDashboardStats>(`dashboard`),
};

const Invitations = {
  getAll: () => axios.get<IRecInviteList>(`rec_invites`),
  getInviteById: (id: number) => axios.get<IRecInvite>(`rec_invites/${id}`),
  create: (invite: IRecInvite) => axios.post<IRecInvite>(`rec_invites`, invite),
  update: (invite: IRecInvite) =>
    axios.put<IRecInvite>(`rec_invites/${invite.id}`, invite),
  delete: (id: number) => axios.delete(`rec_invites/${id}`),
  send: (id: number) => axios.post<IRecInvite>(`rec_invites/${id}/send_invite`),
};

const Universities = {
  get: () => axios.get<{ universities: IUniversity[] }>(`universities`),
};

const Billing = {
  getToken: () =>
    axios.get<{ token: string; price: string | number | null }>(
      `payment_token`
    ),
  checkout: (nonce: string) =>
    axios.post<{ success: boolean; message: string; validation_errors: any[] }>(
      `checkout`,
      {
        payment_method_nonce: nonce,
      }
    ),
};

const Profile = {
  get: () => axios.get<IProfile>(`profile`),
  getResumeDownloadUrl: (userId: number) =>
    axios.get<{ resume_download_url: string }>(`member/${userId}/resume_url`),
  update: (profile: IProfile) => axios.patch<IProfile>(`profile`, profile),
};

const GreekAffiliations = {
  getAll: () => axios.get<IAffiliation[] | []>(`profile/greek_affiliations`),
  getById: (id: number) =>
    axios.get<IAffiliation>(`profile/greek_affiliations/${id}`),
  create: (formData: IAffiliation) =>
    axios.post<IAffiliation>(`profile/greek_affiliations`, formData),
  update: (id: number, formData: IAffiliation) =>
    axios.patch<IAffiliation>(`profile/greek_affiliations/${id}`, formData),
  delete: (id: number) => axios.delete(`profile/greek_affiliations/${id}`),
};

const Activities = {
  getAll: () => axios.get<IActivity[] | []>(`profile/activities`),
  getById: (id: number) => axios.get<IActivity>(`profile/activities/${id}`),
  create: (formData: IActivity) =>
    axios.post<IActivity>(`profile/activities`, formData),
  update: (id: number, formData: IActivity) =>
    axios.patch<IActivity>(`profile/activities/${id}`, formData),
  delete: (id: number) => axios.delete(`profile/activities/${id}`),
};

const Awards = {
  getAll: () => axios.get<IAward[] | []>(`profile/awards`),
  getById: (id: number) => axios.get<IAward>(`profile/awards/${id}`),
  create: (formData: IAward) => axios.post<IAward>(`profile/awards`, formData),
  update: (id: number, formData: IAward) =>
    axios.patch<IAward>(`profile/awards/${id}`, formData),
  delete: (id: number) => axios.delete(`profile/awards/${id}`),
};

const CommunityActivities = {
  getAll: () =>
    axios.get<ICommunityActivity[] | []>(`profile/community_activities`),
  getById: (id: number) =>
    axios.get<ICommunityActivity>(`profile/community_activities/${id}`),
  create: (formData: ICommunityActivity) =>
    axios.post<ICommunityActivity>(`profile/community_activities`, formData),
  update: (id: number, formData: ICommunityActivity) =>
    axios.patch<ICommunityActivity>(
      `profile/community_activities/${id}`,
      formData
    ),
  delete: (id: number) => axios.delete(`profile/community_activities/${id}`),
};

const Jobs = {
  getAll: () => axios.get<IJob[] | []>(`profile/jobs`),
  getById: (id: number) => axios.get<IJob>(`profile/jobs/${id}`),
  create: (formData: IJob) => axios.post<IJob>(`profile/jobs`, formData),
  update: (id: number, formData: IJob) =>
    axios.patch<IJob>(`profile/jobs/${id}`, formData),
  delete: (id: number) => axios.delete(`profile/jobs/${id}`),
};

const RecRequests = {
  getAll: () => axios.get<{ requests: IRecRequestSummary[] }>(`writer_recs`),
  getById: (id: number) => axios.get<IRecRequest>(`writer_recs/${id}`),
  setStatus: (id: number, status: string) =>
    axios.put(`writer_recs/${id}`, { status }),
};

const Notifications = {
  getAll: () => axios.get<INotification[]>(`notifications`),
};

export default {
  Auth,
  Billing,
  Dashboard,
  User,
  Invitations,
  Universities,
  Profile,
  GreekAffiliations,
  Activities,
  Awards,
  CommunityActivities,
  Jobs,
  Files,
  RecRequests,
  Notifications,
};
