import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { FormGroup, Label, Input, Button, Modal, ModalBody } from "reactstrap";
import FileDetails from "./FileDetails";
import { IFileItem, IContentfulPage } from "../interfaces";
import { getStatus } from "../pages/RecRequests";
import ModalFooter from "reactstrap/lib/ModalFooter";

interface Props {
  status: string;
  files: IFileItem[];
  recId: number;
  filesDownloaded: boolean;
  progress: number;
  pagesContent: IContentfulPage[];
  setStatus(status: string): void;
  goToResume(): void;
  updateRecRequest(): void;
}

const Guide = (props: Props) => {
  const guideContent = props.pagesContent.find(
    p => p.title === "Rec Detail - Guide"
  );
  const recStatusContent = props.pagesContent.find(
    p => p.title === "Rec Detail - Rec Status"
  );
  const resumeContent = props.pagesContent.find(
    p => p.title === "Rec Detail - Resume"
  );
  const filesContent = props.pagesContent.find(
    p => p.title === "Rec Detail - Files"
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  return (
    <div className="pt-2 text-muted">
      <h2 className="text-dark">Guide</h2>
      {guideContent && <ReactMarkdown source={guideContent.instructions} />}
      <h4 className="text-dark d-inline pr-2">Rec Status</h4>
      <span>
        {getStatus(props.status)}{" "}
        <span className="text-secondary">({props.progress}%)</span>
      </span>
      {recStatusContent && <p>{recStatusContent.instructions}</p>}
      <FormGroup tag="fieldset">
        <FormGroup check disabled>
          <Label check>
            <Input
              type="checkbox"
              disabled
              checked={props.status !== "" && props.status !== "unopened"}
            />
            Reviewed Rec Guide and national sorority guidelines
          </Label>
        </FormGroup>
        <FormGroup check disabled>
          <Label check>
            <Input type="checkbox" checked={props.filesDownloaded} disabled />{" "}
            Reviewed and downloaded required files
          </Label>
        </FormGroup>
        <FormGroup check disabled={props.status !== "in_progress"}>
          <Label check>
            <Input
              type="checkbox"
              checked={props.status === "sent"}
              disabled={
                props.status !== "in_progress" || !props.filesDownloaded
              }
              onClick={() => setShowConfirmation(true)}
            />{" "}
            Sent final Sorority Packet to sorority electronically or via snail
            mail
          </Label>
        </FormGroup>
      </FormGroup>
      <h4 className="text-dark mt-2">Resumé</h4>
      {resumeContent && <p>{resumeContent.instructions}</p>}
      <Button color="primary" onClick={props.goToResume}>
        View
      </Button>
      <h4 className="text-dark mt-3">Files</h4>
      {filesContent && <p>{filesContent.instructions}</p>}
      <FileDetails
        files={props.files}
        recId={props.recId}
        updateRecRequest={props.updateRecRequest}
      />
      <Modal isOpen={showConfirmation}>
        <ModalBody>
          Are you sure you want to mark this recommendation as 'Sent to the
          Sorority'? Choose Yes or No
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowConfirmation(false)}>
            No
          </Button>
          <Button
            color="primary"
            onClick={() => {
              props.setStatus("sent");
              setShowConfirmation(false);
            }}
          >
            Yes
          </Button>
        </ModalFooter>
        {/* props.setStatus("sent") */}
      </Modal>
    </div>
  );
};
export default Guide;
