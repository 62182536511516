import React from "react";
import {
  FormFeedback,
  Input,
  InputGroupAddon,
  InputGroup,
  InputProps,
} from "reactstrap";

interface Props extends InputProps {
  error?: string;
}

const PasswordPeekInput: React.FC<Props> = ({
  type: _,
  className,
  error,
  ...rest
}) => {
  const [isPeeked, setIsPeeked] = React.useState<boolean>(true);

  return (
    <InputGroup>
      <Input
        className={`${className} bg-white border-right-0 fs-exclude`}
        type={isPeeked ? "text" : "password"}
        autoComplete="off"
        {...rest}
      />
      <InputGroupAddon addonType="append" className="bg-white border-right-0">
        <button
          className="password-peek-input--button form-control"
          onClick={e => {
            e.preventDefault();
            setIsPeeked(prev => !prev);
          }}
        >
          <i className="fas fa-eye-slash" />
        </button>
      </InputGroupAddon>
      <FormFeedback>{error}</FormFeedback>
    </InputGroup>
  );
};

export default PasswordPeekInput;
