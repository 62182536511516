import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  FormText,
} from "reactstrap";
import agent from "../agent";
import iconLogoC from "../images/icon-logo-c.svg";
import Layout from "../components/Layout";
import PasswordPeekInput from "../components/PasswordPeekInput";

const formSchema = yup.object().shape({
  password: yup
    .string()
    .min(6)
    .matches(
      /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{6,})/,
      "Password must contain at least one number"
    )
    .label("Password")
    .required(),
  password_confirm: yup
    .string()
    .label("Confirm password")
    .oneOf([yup.ref("password"), null], "Passwords do not match")
    .required(),
});

interface IResetFormValues {
  password: string;
  password_confirm: string;
}

interface IPasswordResetProps extends RouteComponentProps<{ token: string }> {
  onReset(): void;
}

const PasswordReset: React.FC<IPasswordResetProps> = props => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  useEffect(() => {
    if (props.token) {
      agent.Auth.verifyResetToken(props.token).catch(() => {
        setErrorMessage(
          "Invalid password reset token, please follow the link found in your email directly to reset your password."
        );
      });
    } else {
      setErrorMessage(
        "No reset token found, please follow the link found in your email directly to reset your password."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleSubmit(
    values: IResetFormValues,
    actions: FormikActions<IResetFormValues>
  ) {
    if (!props.token) {
      return;
    }
    agent.Auth.changePassword(values.password, props.token)
      .then(res => {
        actions.setSubmitting(false);
        props.onReset();
      })
      .catch(err => {
        actions.setSubmitting(false);
        if (err && err.response) {
          setErrorMessage(err.response.data.errors[0].detail);
        } else {
          setErrorMessage("There was a problem sending the reset email.");
        }
      });
  }

  return (
    <Layout>
      <Container className="pt-3">
        <div className="text-center">
          <img src={iconLogoC} alt="Rec Hub logo icon" className="mb-3" />
          <h1 className="text-center mb-3">Change Password</h1>
        </div>
        <Row>
          <Col sm={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
            <Card>
              <CardBody>
                <Formik
                  initialValues={{
                    password: "",
                    password_confirm: "",
                  }}
                  validationSchema={formSchema}
                  onSubmit={handleSubmit}
                  render={(formikBag: FormikProps<IResetFormValues>) => (
                    <Form onSubmit={formikBag.handleSubmit}>
                      <FormGroup>
                        <Label>
                          <span className="text-danger">*</span> Password
                        </Label>
                        <PasswordPeekInput
                          name="password"
                          type="password"
                          autoComplete="new-password"
                          onChange={formikBag.handleChange}
                          invalid={
                            !!(
                              formikBag.errors.password &&
                              formikBag.touched.password
                            )
                          }
                        />
                        <FormText>
                          Must be at least six or more characters and at least 1
                          number.
                        </FormText>
                        <FormFeedback>{formikBag.errors.password}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          <span className="text-danger">*</span> Confirm
                          Password
                        </Label>
                        <PasswordPeekInput
                          name="password_confirm"
                          type="password"
                          autoComplete="new-password"
                          onChange={formikBag.handleChange}
                          error={formikBag.errors.password_confirm}
                          invalid={
                            !!(
                              formikBag.errors.password_confirm &&
                              formikBag.touched.password_confirm
                            )
                          }
                        />
                      </FormGroup>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={formikBag.isSubmitting}
                      >
                        Change Password
                      </Button>
                      {errorMessage && (
                        <Alert color="danger" className="mt-3">
                          {errorMessage}
                        </Alert>
                      )}
                    </Form>
                  )}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default PasswordReset;
